import fuelPlaceholderData from '@/services/api/fuelPlaceholderData.json';
import milkPlaceholderData from '@/services/api/milkPlaceholderData.json';

import { Product } from '@/store/models/Product';
import { Constants } from '../Constants';
import { productType } from '../types/ProductType';

export class PlaceholderData {
  public product: Product;
  public app: productType;

  constructor(app: productType, entity: Product | null) {
    this.app = app;
    this.product = this.insertPlaceholderData(entity, app);
  }

  public getRelevantData(app: productType) {
    switch (app) {
      case Constants.PRODUCT_TYPE_MILK:
        return milkPlaceholderData.entity;
      case Constants.PRODUCT_TYPE_FUEL:
        return fuelPlaceholderData.entity;
      default:
        return fuelPlaceholderData.entity;
    }
  }

  public insertPlaceholderData(entity: Product | null, app: productType) {
    const placeholderData: any = this.getRelevantData(app);
    let newProduct: any = {};
    newProduct = this.replaceObjectWithPlacholder(entity, placeholderData);
    newProduct.metrics = this.replaceObjectWithPlacholder(
      entity?.metrics,
      placeholderData.metrics
    );
    return newProduct;
  }

  public replaceObjectWithPlacholder(original: any, placeholder: any) {
    const newObject: any = {};
    (Object.keys(placeholder) as (keyof Product)[]).forEach(key => {
      if (original[key] == null || original[key] == undefined) {
        newObject[key] = placeholder[key];
      } else {
        newObject[key] = original[key];
      }
    });
    return newObject;
  }
}
