import { GAEvent } from '@/services/ga/events/GAEvent';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventGraphModalOpen extends GAEvent {
  public eventName = 'graph-modal-open';
  public graphName?: string;
  public productId?: number;
  public entityId?: number;
  public entityType?: EntityApiType;

  constructor(
    graphName?: string,
    productId?: number,
    entityId?: number,
    entityType?: EntityApiType
  ) {
    super();
    this.graphName = graphName;
    this.productId = productId;
    this.entityId = entityId;
    this.entityType = entityType;
  }
}
