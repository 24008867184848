
import Withdrawal from '@/services/api/models/Withdrawal';
import { FetchFuelEventsData } from '@/services/api/ProductApi';
import { trackEvent } from '@/services/Mixpanel';
import { Product } from '@/store/models/Product';
import groupBy from '@/utils/GroupBy';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class FuelActivityList extends mixins(AppName) {
  @Prop() selectProd!: Product | null;
  @Prop() tankId!: number;

  private events: {
    eventType: string;
    timestamp: number;
    value: string;
  }[] = [];
  public noEvents = true;
  public loading = true;
  public view = 'all';
  public filteredEvents: {
    eventType: string;
    timestamp: number;
    value: string;
  }[] = [];

  created() {
    this.$root.$on('activityUpdated', this.getEvents);
  }

  mounted() {
    if (this.selectProd && this.selectProd.entityId) {
      this.getEvents();
    }
  }

  @Watch('selectProd')
  updateEvents() {
    this.view = 'all';
    this.getEvents();
  }

  public getEvents(
    from = dayjs()
      .subtract(7, 'day')
      .unix()
      .toString(),
    to = dayjs()
      .unix()
      .toString()
  ) {
    if (this.selectProd) {
      const selectProdId =
        this.tankId != -1 ? this.tankId : this.selectProd.entityId;
      if (selectProdId) {
        this.noEvents = true;
        this.loading = true;
        FetchFuelEventsData(
          selectProdId.toString(),
          from,
          to,
          'Fuel Activity List'
        ).then(values => {
          if (!values.withdrawal || values.withdrawal.length == 0) {
            this.noEvents = true;
            this.loading = false;
          } else {
            this.noEvents = false;
            const groupedWithdrawals = this.groupDays(values.withdrawal);
            const withdrawals = groupedWithdrawals.map(event => {
              return {
                eventType: 'Usage ',
                value: Math.round(event.value).toLocaleString(),
                timestamp: event.timestamp
              };
            });
            const groupedFills = this.groupDays(values.refill ?? []);
            const fill = groupedFills.map(event => {
              return {
                eventType: 'Fill ',
                value: Math.round(event.value).toLocaleString(),
                timestamp: event.timestamp
              };
            });
            const balance =
              values.daily?.map(event => {
                return {
                  eventType: 'Balance ',
                  value: Math.round(event.value).toLocaleString(),
                  timestamp: event.timestamp
                };
              }) ?? [];
            const events = [...withdrawals, ...fill, ...balance];
            events.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
            this.events = events;
            this.changeView(this.view);
          }
          this.loading = false;
        });
      }
    }
  }

  public groupDays(events: Withdrawal[]) {
    const grouped = Array.from(
      groupBy(events, (value: any) =>
        dayjs.unix(value?.timestamp).format('D-MM-YYYY')
      )
    );
    const sumValues: Withdrawal[] = [];
    grouped.forEach(dayList => {
      if (dayList[1].length > 1) {
        let totalValue = 0;
        dayList[1].forEach((element: Withdrawal) => {
          totalValue += element.value;
        });
        sumValues.push({ ...dayList[1][0], value: totalValue });
      } else if (dayList[1].length == 1) {
        sumValues.push({ ...dayList[1][0] });
      }
    });
    return sumValues;
  }

  public changeView(view: string) {
    trackEvent(`User changed view on ${this.getAppName(false)} activity list`, {
      view: view
    });
    this.view = view;
    this.filteredEvents = this.events.filter(event => {
      if (view == 'usage') {
        return event.eventType == 'Usage ';
      } else if (view == 'fill') {
        return event.eventType == 'Fill ';
      } else if (view == 'balance') {
        return event.eventType == 'Balance ';
      }
      return true;
    });
    if (this.filteredEvents.length == 0) {
      this.noEvents = true;
    } else {
      this.noEvents = false;
    }
  }
}
