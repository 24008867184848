
import { Product } from '@/store/models/Product';
import { PlaceholderData } from '@/utils/classes/PlaceholderData';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import VolumePercentGraph from '../graphs/VolumePercentGraph.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    VolumePercentGraph
  },
  methods: {
    getToday,
    getDayjsFromUnix
  }
})
export default class VolumePercentGraphModal extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop({ required: true, type: Object }) currentEntity!: Product;
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: Boolean }) isNotLive!: boolean;

  public modalId = 'volumePercentGraph';

  public showVolumePercentGraphModal() {
    this.$root.$emit('bv::show::modal', this.modalId, '#btnShow');
  }

  public closeVolumePercentGraphModal() {
    this.$root.$emit('bv::hide::modal', this.modalId, '#btnHide');
  }

  getPlaceholderEntity() {
    return new PlaceholderData(
      this.getAppName(false) as productType,
      this.currentEntity
    ).product;
  }
}
