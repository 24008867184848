
import { WaterTankVolumePercent } from '@/services/api/models/WaterTankVolumePercent';
import { FetchWaterTankGraphData } from '@/services/api/ProductApi';
import { Product } from '@/store/models/Product';
import { getAppBackgroundColour, getAppColour } from '@/utils/AppName';
import { formatPercent } from '@/utils/formatters/DisplayValueFormatters';
import '@/utils/RoundedBar';
import Chart, {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartTooltipItem
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class VolumePercentGraph extends Vue {
  @Prop({ required: true, type: Number }) entityId!: number;
  @Prop({ required: true, type: Object }) selectedEntity!: Product;
  @Prop({ required: true, type: String }) location!: string;
  @Prop({ required: true, type: Boolean }) disabled!: boolean;

  public loading = true;
  public noData = false;
  private chart!: Chart;
  private graphData!: WaterTankVolumePercent[];
  public volumeData: { timestamp: number; percent: number }[] = [];
  public dateOffset = 0;

  get timePeriod() {
    return {
      from: moment().subtract(this.dateOffset + 1, 'months'),
      to: moment().subtract(this.dateOffset, 'months')
    };
  }

  get daysBetween() {
    return moment
      .duration(this.timePeriod.to.diff(this.timePeriod.from))
      .asDays();
  }

  public get endIsCurrent() {
    return (
      this.timePeriod.to.format('MMMM YYYY') === moment().format('MMMM YYYY')
    );
  }

  public changeOffset(num: number): void {
    this.dateOffset += num;
  }

  get options() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 5,
          right: 20
        }
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          padding: 20,
          boxWidth: 12
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: (tooltipItems: any) => {
            return formatPercent(tooltipItems.yLabel);
          }
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: !this.disabled,
              autoSkip: true,
              maxTicksLimit: 3,
              source: 'data',
              min: 0,
              max: 100,
              callback: function(value) {
                return value + '%';
              }
            },
            afterFit: function(scaleInstance) {
              scaleInstance.width = 50;
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              display: !this.disabled,
              autoSkip: true,
              maxTicksLimit: this.location == 'sidebar' ? 4 : 12,
              source: 'labels'
            },
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'D MMM YY'
              }
            }
          }
        ]
      },
      elements: {
        point: {
          pointRadius: 0
        },
        line: {
          tension: 0
        }
      }
    } as ChartOptions;
  }

  mounted() {
    if (this.entityId) {
      this.updateData();
    }
  }

  @Watch('timePeriod')
  @Watch('entityId')
  triggerUpdate() {
    this.updateData();
  }

  public showVolScoreGraphModal() {
    this.$root.$emit('bv::show::modal', 'volumePercentGraph', '#btnShow');
  }

  public initChart() {
    Chart.pluginService.register(ChartDataLabels);
    if (this.graphData) {
      const data = this.formatData();
      this.createChart(data);
      this.setChartYAxisMax();
      if (this.chart) this.chart.update();
    } else {
      this.loading = true;
    }
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `line-${this.entityId}-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'line',
        data: chartData,
        options: this.options
      };
      this.chart = new Chart(canvas as HTMLCanvasElement, options);
    }
  }

  public formatData() {
    const volumeData: {
      percent: number;
      timestamp: number;
    }[] = this.graphData.filter(
      (_, index) => index % (this.location == 'sidebar' ? 25 : 5) === 0
    );
    const labels: number[] = [];

    for (let j = 0; j < this.daysBetween; j++) {
      labels.push(
        moment(this.timePeriod.from)
          .add(j, 'days')
          .valueOf()
      );
    }

    return {
      datasets: [
        {
          label: 'Water Volume',
          borderColor: getAppColour(false),
          backgroundColor: getAppBackgroundColour(),
          pointRadius: 0,
          datalabels: {
            display: false
          },
          data: volumeData.map((entry: any) => {
            return {
              x: new Date(entry.timestamp * 1000),
              y: Math.round(+entry.percent)
            };
          })
        }
      ],
      labels: labels
    };
  }

  public updateData() {
    this.loading = true;
    FetchWaterTankGraphData(
      this.entityId.toString(),
      this.timePeriod.from.unix().toString(),
      this.timePeriod.to.unix().toString(),
      'Water Volume Graph'
    ).then((data: WaterTankVolumePercent[]) => {
      this.noData = data.length == 0;
      this.graphData = data;
      try {
        if (!this.chart) {
          this.initChart();
        } else {
          const data: ChartData = this.formatData();
          this.chart.options = this.options;
          this.chart.data = data;
          this.setChartYAxisMax();
          if (this.chart.update()) this.chart.update();
        }
      } catch (e) {
        this.toast(e);
      }
      this.loading = false;
    });
  }

  public setChartYAxisMax() {
    if (
      this.chart?.options?.scales?.yAxes &&
      this.chart?.options?.scales?.yAxes[0].ticks
    ) {
      //This sets the graph to display the vat capacity as the maximum when the graph is empty
      this.chart.options.scales.yAxes[0].ticks.suggestedMax = !this.noData
        ? 0
        : this.selectedEntity
        ? this.selectedEntity.capacity
        : 5000;
    }
  }

  public toast(e: any) {
    this.$bvToast.toast(`Graph not updated - ${e}`, {
      title: 'Internal error',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
  }
}
