
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import LowLevelTankSettings from '../sidebar/LowLevelTankSettings.vue';
import UsageDayPatternSettings from '../sidebar/UsageDayPatternSettings.vue';

@Component({
  components: {
    LowLevelTankSettings,
    UsageDayPatternSettings
  }
})
export default class FuelCustomSettingsGroup extends Vue {}
