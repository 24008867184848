
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import ComplianceMethodSelection from '../items/ComplianceMethodSelection.vue';
import PartnerCustomNames from '../items/PartnerCustomNames.vue';
import CustomFieldDataList from '../list/CustomFieldDataList.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    CustomFieldDataList,
    PartnerCustomNames,
    ComplianceMethodSelection,
    SidebarCard
  },
  methods: {}
})
export default class SiteSettingsSideContent extends mixins(
  AppName,
  ConstantsMixin
) {

  @Prop({ required: true, type: Boolean })
  public canEdit!: boolean;

  @Prop({ required: true, type: Boolean })
  public canEditSiteSettings!: boolean;

}
