
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import {
  UpdateCustomLabelIdData,
  UpdateCustomLabelNameData
} from '@/services/api/CustomFieldApi';
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput
  }
})
export default class PartnerCustomNames extends mixins(AppName) {
  @Prop() tankIndex!: number;
  @Prop() siteView!: boolean;

  @Prop({ required: true, type: Boolean })
  public disabled!: boolean;

  public updated = false;
  public partnerDefinedName = '';
  public partnerDefinedId = '';
  public appColour = 'fuelColour';

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }

  mounted() {
    this.appColour = this.getAppColour(true);
    this.loadLabels();
    //Still new to update to get the specific entity custom field def, will update when done settings page
  }

  @Watch('currentProduct')
  changeInProduct() {
    this.updated = false;
    this.loadLabels();
  }

  @Watch('tankIndex')
  changeInTank() {
    this.updated = false;
    this.loadLabels();
  }

  public loadLabels() {
    if (this.siteView) {
      if (this.currentProduct) {
        this.partnerDefinedName = this.currentProduct?.partnerDefinedName
          ? this.currentProduct?.partnerDefinedName
          : this.currentProduct?.siteName ?? '';
        this.partnerDefinedId = this.currentProduct?.partnerDefinedId ?? '';
      }
    } else {
      if (this.currentProduct && this.currentProduct?.entities) {
        this.partnerDefinedName =
          this.currentProduct.entities[this.tankIndex]
            .partnerDefinedEntityName ?? '';
        this.partnerDefinedId =
          this.currentProduct.entities[this.tankIndex].partnerDefinedEntityId ??
          '';
      }
    }
  }

  public newInput(newValue: string) {
    this.updated = true;
  }

  public submitUpdateData() {
    if (this.currentProduct) {
      if (this.partnerDefinedName != '') {
        UpdateCustomLabelNameData({
          partnerId: User.orgId,
          entityType: 'products',
          entityId: this.currentProduct.productId,
          data: this.partnerDefinedName
        }).then(() => {
          if (this.partnerDefinedId == '') {
            this.$root.$emit('customFieldsUpdated');
            this.$emit('updatedLabels', this.partnerDefinedName, '');
            this.updated = false;
          }
        });
      }
      if (this.partnerDefinedId != '') {
        UpdateCustomLabelIdData({
          partnerId: User.orgId,
          entityType: 'products',
          entityId: this.currentProduct.productId,
          data: this.partnerDefinedId
        }).then(() => {
          this.$root.$emit('customFieldsUpdated');
          this.$emit(
            'updatedLabels',
            this.partnerDefinedName,
            this.partnerDefinedId
          );
          this.updated = false;
        });
      }
    }
  }
}
