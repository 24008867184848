var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentEntity)?_c('div',[_c('p',{staticClass:"settingsLabel px-3"},[_vm._v(" Expected Usage Days "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Set the days this tank is expected to use fuel. 'Average daily use' and 'estimated days until empty' will be affected by this value."}},[_c('BIcon',{staticClass:"mx-1",attrs:{"variant":"mediumGrey","icon":"info-circle"}})],1)]),_c('BForm',{staticClass:"p-3",style:(_vm.disabled ? 'opacity: 0.5' : ''),on:{"submit":function($event){$event.preventDefault();return _vm.submitUpdateData.apply(null, arguments)}}},[_c('div',{staticClass:"text-left"},[_c('FormDropdownInput',{attrs:{"inputAttrs":{
          id: 'usageDayPatternSettings',
          label: 'Expected Usage Days',
          options: Object.values(_vm.usageDayPatternOptions),
          variant: 'fuelColour',
          value: _vm.usageDayPattern,
          disabled: _vm.disabled
        },"id":"usageDayPatternSettings"},on:{"input":_vm.newInput},model:{value:(_vm.usageDayPattern),callback:function ($$v) {_vm.usageDayPattern=$$v},expression:"usageDayPattern"}})],1),(_vm.updated && !_vm.disabled)?_c('BButton',{attrs:{"type":"submit","variant":"fuelColour"},on:{"click":_vm.submitUpdateData}},[_vm._v("Save Changes")]):_vm._e()],1),_c('Toast',{attrs:{"id":"changesAppliedUsage","title":"Alert levels updated"}},[_c('p',[_vm._v(" Changes made to usage day pattern settings ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }