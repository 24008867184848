
import { Product } from '@/store/models/Product';
import { PlaceholderData } from '@/utils/classes/PlaceholderData';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import VolumeScoreGraph from '../graphs/VolumeScoreGraph.vue';
import AppName from '../mixin/AppName.vue';
import VolumeScoreGraphModal from '../modal/VolumeScoreGraphModal.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventGraphChangeDate } from '@/services/ga/events/EventGraphChangeDate';
import { EventGraphModalOpen } from '@/services/ga/events/EventGraphModalOpen';

@Component({
  components: { VolumeScoreGraph, VolumeScoreGraphModal, SidebarCard },
  methods: { getDayjsFromUnix, getToday }
})
export default class VolumeScoreGraphCard extends mixins(AppName) {
  @Prop() currentEntity!: Product;
  @Prop() isNotLive!: boolean;

  public showVolumeScoreGraphModal() {
    if (!this.isNotLive) {
      GA.event<EventGraphModalOpen>(
        this.$gtag,
        new EventGraphModalOpen(
          'volume-milk-score',
          this.currentEntity?.productId,
          this.currentEntity?.entityId,
          this.getEntityApiWord()
        )
      );

      this.$root.$emit('bv::show::modal', 'volScoreGraphModal', '#btnShow');
    }
  }

  getPlaceholderEntity() {
    return new PlaceholderData(
      this.getAppName(false) as productType,
      this.currentEntity
    ).product;
  }
}
