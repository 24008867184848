var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentProduct)?_c('div',[(_vm.customFieldData && _vm.customFieldData.length > 0)?_c('BForm',{staticClass:"p-3 text-left",on:{"submit":function($event){$event.preventDefault();return _vm.submitUpdateData.apply(null, arguments)}}},[_vm._l((_vm.customFields),function(field,index){return _c('div',{key:index,staticClass:"text-left"},[(field.type == 'List Entry')?_c('FormDropdownInput',{attrs:{"inputAttrs":{
          id: `${
            _vm.siteView ? 'site' : _vm.getEntityName(false)
          }CustFieldDef${index}`,
          label: field.label,
          options: field.listOptions,
          variant: _vm.getAppColour(true),
          value: _vm.customFieldData[index].value,
          disabled: _vm.disabled
        },"id":`${_vm.siteView ? 'site' : _vm.getEntityName(false)}CustFieldDef${index}`},on:{"input":_vm.newInput},model:{value:(_vm.customFieldData[index].value),callback:function ($$v) {_vm.$set(_vm.customFieldData[index], "value", $$v)},expression:"customFieldData[index].value"}}):_c('FormTextInput',{attrs:{"inputAttrs":{
          id: `${
            _vm.siteView ? 'site' : _vm.getEntityName(false)
          }CustFieldDef${index}`,
          label: field.label,
          labelClass: 'text-primary',
          type: 'text',
          variant: _vm.getAppColour(true),
          value: _vm.customFieldData[index].value,
          disabled: _vm.disabled
        },"id":`${_vm.siteView ? 'site' : _vm.getEntityName(false)}CustFieldDef${index}`},on:{"input":_vm.newInput},model:{value:(_vm.customFieldData[index].value),callback:function ($$v) {_vm.$set(_vm.customFieldData[index], "value", $$v)},expression:"customFieldData[index].value"}})],1)}),(_vm.updated && !_vm.disabled)?_c('BButton',{attrs:{"type":"submit","variant":_vm.getAppColour(true)},on:{"click":_vm.submitUpdateData}},[_vm._v("Save Changes")]):_vm._e()],2):_c('div',[_c('p',{staticClass:"p-2 text-left pl-3"},[_vm._v("No custom field data to show")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }