
import CustomField from '@/store/models/CustomField';
import CustomFieldData from '@/store/models/CustomFieldData';
import { Product } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import { generateCustomFieldDisplayData } from '@/utils/CustomFields';
import {
  getCriticalLevelThreshold,
  getLowLevelThreshold,
  getUsageDayPattern
} from '@/utils/FuelUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import DisplayInfoItem from '../common/DisplayInfoItem.vue';
import SidebarCard from '../common/SidebarCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    DisplayInfoItem,
    SidebarCard
  },
  methods: {
    isAdmin,
    getUsageDayPattern,
    getLowLevelThreshold,
    getCriticalLevelThreshold
  }
})
export default class DetailsCard extends mixins(AppName, ConstantsMixin) {
  @Prop() siteView!: boolean;

  get currentEntity(): Product | null {
    return ProductStore.currentEntity ?? null;
  }

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }

  get customFields(): CustomField[] | null {
    return (
      (this.siteView
        ? CustomStore.customFields
        : CustomStore.customEntityFields) ?? null
    );
  }

  get customFieldData(): CustomFieldData[] {
    return generateCustomFieldDisplayData(
      this.currentProduct,
      this.currentEntity,
      this.siteView,
      this.customFields
    );
  }

  get hasFuelSettings() {
    if (!this.isApp(this.PRODUCT_TYPE_FUEL)) return false;
    return (
      getLowLevelThreshold(this.currentProduct) ||
      getCriticalLevelThreshold(this.currentProduct) ||
      getUsageDayPattern(this.currentProduct)
    );
  }

  get hasDetails() {
    if (this.siteView) {
      return (
        this.currentProduct?.address ||
        this.currentProduct?.partnerDefinedName != null ||
        this.currentProduct?.partnerDefinedId != null ||
        this.customFieldData.some(customField => customField.value != '')
      );
    } else {
      return (
        this.customFieldData.some(customField => customField.value != '') ||
        this.hasFuelSettings
      );
    }
  }

  public scrollSettingsIntoView() {
    this.$root.$emit(
      'switchToSettingsTab',
      this.siteView ? 'siteSettings' : 'entitySettings'
    );
  }
}
