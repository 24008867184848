
import { Product } from '@/store/models/Product';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import CombinedVolumeGraph from '../graphs/CombinedVolumeGraph.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    CombinedVolumeGraph
  },
  methods: {
    getToday,
    getDayjsFromUnix
  }
})
export default class CombinedVolumeGraphModal extends mixins(AppName) {
  @Prop() currentProduct!: Product;
  @Prop() isNotLive!: boolean;

  private readonly placeholderTimetamp = 1664738629;

  public closeVolGraphModal() {
    this.$root.$emit('bv::hide::modal', 'volGraphModal', '#btnHide');
  }
}
