var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"mt-2"},[_c('BButton',{staticClass:"mx-1",attrs:{"variant":_vm.view == 'monthly'
          ? _vm.disabled
            ? 'mediumGrey'
            : _vm.getAppColour(true)
          : 'grey',"pill":"","title":"Monthly total","size":"sm"},on:{"click":function($event){return _vm.changeView('monthly')}}},[_c('BIcon',{staticClass:"text-white",attrs:{"icon":"check"}}),_vm._v("Monthly ")],1),_c('BButton',{staticClass:"mx-1",attrs:{"variant":_vm.view == 'weekly'
          ? _vm.disabled
            ? 'mediumGrey'
            : _vm.getAppColour(true)
          : 'grey',"pill":"","title":"Weekly total (last 16 weeks)","size":"sm"},on:{"click":function($event){return _vm.changeView('weekly')}}},[_c('BIcon',{staticClass:"text-white",attrs:{"icon":"check"}}),_vm._v("Weekly ")],1),_c('BButton',{staticClass:"mx-1",attrs:{"variant":_vm.view == 'daily'
          ? _vm.disabled
            ? 'mediumGrey'
            : _vm.getAppColour(true)
          : 'grey',"pill":"","title":"Daily total (last 25 days)","size":"sm"},on:{"click":function($event){return _vm.changeView('daily')}}},[_c('BIcon',{staticClass:"text-white",attrs:{"icon":"check"}}),_vm._v("Daily ")],1),_c('label',[_c('BIcon',{staticClass:"mx-2",attrs:{"hidden":_vm.view == 'monthly',"icon":"chevron-left","title":`View previous ${this.view == 'weekly' ? '16 weeks' : '25 days'}`},on:{"click":function($event){return _vm.changeTimePeriod('left')}}}),_c('BIcon',{attrs:{"hidden":_vm.view == 'monthly',"icon":"chevron-right","title":`View next ${this.view == 'weekly' ? '16 weeks' : '25 days'}`},on:{"click":function($event){return _vm.changeTimePeriod('right')}}})],1)],1),_c('BContainer',{staticClass:"mt-4",on:{"click":function($event){return _vm.showUsageGraphModal()}}},[_c('div',[_c('div',{staticClass:"text-center",style:(_vm.loading ? 'display:inline-block' : 'display:none')},[_c('b-spinner',{staticClass:"mt-4",attrs:{"variant":_vm.getAppColour(true),"id":"spinner","label":"Text Centered"}})],1),_c('div',{style:(!_vm.loading && _vm.noData ? 'display:inline-block' : 'display:none')},[_c('p',[_vm._v("No data to show")])]),_c('canvas',{ref:`fuelBar-${_vm.location}`,style:(!_vm.loading && !_vm.noData ? 'display:inline-block' : 'display:none')})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }