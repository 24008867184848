
import BurndownActivityGraphCard from '@/components/cards/BurndownActivityGraphCard.vue';
import UsageGraphCard from '@/components/cards/UsageGraphCard.vue';
import ProductStore from '@/store/modules/ProductStore';
import VolumePercentGraphCard from '@/components/cards/VolumePercentGraphCard.vue';
import { Constants } from '@/utils/Constants';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import VolumeScoreGraphCard from '../cards/VolumeScoreGraphCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import SidebarCard from '../common/SidebarCard.vue';
import { isWaterTank, isWaterMeter } from '@/utils/WaterUtils';

@Component({
  components: {
    UsageGraphCard,
    BurndownActivityGraphCard,
    VolumeScoreGraphCard,
    VolumePercentGraphCard,
    SidebarCard
  },
  computed: {
    currentEntity: () => ProductStore.currentEntity ?? undefined
  }
})
export default class EntityGraphsGroup extends mixins(AppName, ConstantsMixin) {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: Boolean }) isNotLive!: boolean;

  get useUsageGraph(): boolean {
    return (
      this.isApp(this.PRODUCT_TYPE_FUEL) ||
      this.isApp(this.PRODUCT_TYPE_FEED) ||
      (this.isApp(this.PRODUCT_TYPE_WATER) && isWaterMeter())
    );
  }

  get useBurndownGraph(): boolean {
    return (
      this.isApp(this.PRODUCT_TYPE_FUEL) || this.isApp(this.PRODUCT_TYPE_FEED)
    );
  }

  get useVolumeGraph(): boolean {
    return this.isApp(this.PRODUCT_TYPE_WATER) && isWaterTank();
  }

  get useVatScaleGraph(): boolean {
    return this.isApp(this.PRODUCT_TYPE_MILK);
  }

  public getAppGraphIds() {
    switch (this.getAppName(false)) {
      case Constants.PRODUCT_TYPE_MILK:
        return ['volumeScoreGraph'];
      case Constants.PRODUCT_TYPE_FUEL:
        return ['burndownGraph', 'comparisonBarGraph'];
      case Constants.PRODUCT_TYPE_FEED:
        return ['burndownGraph', 'comparisonBarGraph'];
      case Constants.PRODUCT_TYPE_WATER:
        return ['comparisonBarGraph'];
      default:
        return [];
    }
  }
}
