
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import EntitySettingsSideContent from './EntitySettingsSideContent.vue';
import SiteSettingsSideContent from './SiteSettingsSideContent.vue';
import { PermissionService } from '@/services/PermissionService';
import SidebarCard from '../common/SidebarCard.vue';

@Component({
  components: {
    SidebarCard,
    SiteSettingsSideContent,
    EntitySettingsSideContent
  }
})
export default class SettingsSideContent extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() entityIndex!: boolean;
  @Prop() selected!: boolean;
  @Prop() width!: number;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  created() {
    this.$root.$on('switchToSettingsTab', (section: string) => {
      setTimeout(() => {
        const element = document.getElementById(section);
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    });
  }

  get canEditCustomFieldsData(): boolean {
    return this.permissionService.canEditCustomFieldsData;
  }

  get canEditSiteSettings(): boolean {
    return this.permissionService.canEditSiteSettings;
  }

  get currentEntity(): Product | null {
    return ProductStore.currentEntity ?? null;
  }

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }
}
