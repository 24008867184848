import { Chart } from 'chart.js';

interface BarDimensions {
  left: number;
  right: number;
  top: number;
  bottom: number;
  signX: number;
  signY: number;
  borderSkipped: string;
}

interface View {
  backgroundColor: string;
  base: number;
  borderColor: string;
  borderSkipped: string;
  borderWidth: number;
  datasetLabel: string;
  height: number;
  horizontal: boolean;
  label: string;
  width: number;
  x: number;
  y: number;
}

function drawTopLeftBorderRadius(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: any
) {
  ctx.quadraticCurveTo(x, y, x + radius, y);
}

function drawTopLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
}

function drawTopRightBorderRadius(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
}

function drawRightLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  ctx.lineTo(x + width, y + height - radius);
}

function drawBottomRightBorderRadius(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height); // bottom right
}

function drawBottomLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  ctx.lineTo(x + radius, y + height);
}

function drawBottomLeftBorderRadius(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  height: number,
  radius: any
) {
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
}

function drawLeftLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: any
) {
  ctx.lineTo(x, y + radius);
}

function stackedBottomBox(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  drawTopLine(ctx, x, radius, y, width, height);
  ctx.quadraticCurveTo(x + width, y, x + width, y); // top right
  drawRightLine(ctx, x, radius, y, width, height);
  drawBottomRightBorderRadius(ctx, x, radius, y, width, height);
  drawBottomLine(ctx, x, radius, y, width, height);
  drawBottomLeftBorderRadius(ctx, x, y, height, radius); // bottom left
  ctx.lineTo(x, y);
  ctx.lineTo(x + radius, y);
}

function stackedTopBox(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  drawTopLine(ctx, x, radius, y, width, height);
  drawTopRightBorderRadius(ctx, x, radius, y, width, height);
  drawRightLine(ctx, x, radius, y, width, height);
  ctx.lineTo(x + width, y + height); // bottom right
  drawBottomLine(ctx, x, radius, y, width, height);
  ctx.lineTo(x, y + height); // bottom left
  drawLeftLine(ctx, x, y, radius);
  drawTopLeftBorderRadius(ctx, x, y, radius);
}

function singleStackedBox(
  ctx: CanvasRenderingContext2D,
  x: number,
  radius: any,
  y: number,
  width: number,
  height: number
) {
  drawTopLine(ctx, x, radius, y, width, height);
  drawTopRightBorderRadius(ctx, x, radius, y, width, height);
  drawRightLine(ctx, x, radius, y, width, height);
  drawBottomRightBorderRadius(ctx, x, radius, y, width, height);
  drawBottomLine(ctx, x, radius, y, width, height);
  drawBottomLeftBorderRadius(ctx, x, y, height, radius); // bottom left
  drawLeftLine(ctx, x, y, radius);
  drawTopLeftBorderRadius(ctx, x, y, radius);
}
// Chart DOES have elements property. Investigating types package.
(Chart as typeof Chart & {
  elements: { Rectangle: any };
}).elements.Rectangle.prototype.draw = function() {
  const stacked = this._chart.config.options.scales.xAxes[0].stacked;
  const ctx = this._chart.ctx as CanvasRenderingContext2D;
  const view: View = this._view;
  const dimensions: BarDimensions = {
    left: view.x - view.width / 2,
    right: view.x + view.width / 2,
    top: view.y,
    bottom: view.base,
    signX: 1,
    signY: view.base > view.y ? 1 : -1,
    borderSkipped: view.borderSkipped || 'bottom'
  };
  ctx.beginPath();
  ctx.fillStyle = view.backgroundColor;
  ctx.strokeStyle = view.borderColor;
  ctx.lineWidth = 0;

  ctx.moveTo(dimensions.left, dimensions.bottom);

  for (let step = 0; step < 4; step++) {
    const width = dimensions.right - dimensions.left;
    const height = dimensions.bottom - dimensions.top;
    const x = dimensions.left;
    const y = dimensions.top;

    let radius = this._chart.config.options.cornerRadius ?? 0;

    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2);
    }

    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2);
    }

    if (height < 0) {
      drawTopLine(ctx, x, radius, y, width, height);
      ctx.quadraticCurveTo(x + width, y, x + width, y - radius);
      ctx.lineTo(x + width, y + height + radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height + radius);
      ctx.lineTo(x, y - radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
    } else if (stacked) {
      if (this._datasetIndex >= 1) {
        // If top bar
        const dataIndex = this._index;
        const dataPointBelow = this._chart.config.data.datasets[0].data[
          dataIndex
        ];
        if (dataPointBelow === 0) {
          singleStackedBox(ctx, x, radius, y, width, height); // Not rendering anything
        } else {
          stackedTopBox(ctx, x, radius, y, width, height);
        }
      } else {
        // Bottom bar
        const dataIndex = this._index;
        const dataPointAbove = this._chart.config.data.datasets[1].data[
          dataIndex
        ];
        if (dataPointAbove === 0) {
          singleStackedBox(ctx, x, radius, y, width, height);
        } else {
          stackedBottomBox(ctx, x, radius, y, width, height); // top left
        }
      }
    } else {
      // Non stacked
      singleStackedBox(ctx, x, radius, y, width, height);
    }
    ctx.fill();
  }
};
