
import '@/utils/RoundedBar.ts';
import { Component, Emit, Model, Vue } from 'vue-property-decorator';

@Component
export default class DayPeriodButtons extends Vue {
  @Model('updateModalPeriod')
  readonly modalPeriod!: number;

  @Emit('updateModalPeriod')
  updateModalPeriod(updateModalPeriod: number) {
    return updateModalPeriod;
  }
}
