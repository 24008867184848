
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class SidebarHeader extends mixins(AppName) {
  @Prop() headerName!: string;
  @Prop() headerId!: string;
  @Prop() hideClose!: boolean;
}
