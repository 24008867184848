
import ProductStore from '@/store/modules/ProductStore';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import SidebarWarning from '../sidebar/SidebarWarning.vue';

@Component({
  components: { SidebarWarning },
  computed: {
    currentProduct: () => ProductStore.currentProduct ?? undefined,
    currentEntity: () => ProductStore.currentEntity ?? undefined
  }
})
export default class FaultImpactWarning extends mixins(
  AppName,
  ConstantsMixin
) {
  public learnMoreOpen = false;
  get learnMoreInfo() {
    switch (ProductStore.currentEntity?.faultCustomerImpact) {
      case this.FAULT_IMPACT_MAINTENANCE:
        return 'Our team are scheduled to perform hardware maintenance on this site. The data displayed remain accurate and trustworthy.';
      case this.FAULT_IMPACT_UNRELIABLE_DATA:
        return 'Data on this site may be intermittent or inaccurate. Our team have a visit scheduled to restore data accuracy.';
      case this.FAULT_IMPACT_NO_DATA:
        return 'Data on this site may be out of date as the hardware is not reporting data. Our team have a visit scheduled to restore data.';
      default:
        return '';
    }
  }
}
