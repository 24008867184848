
import { MilkProductTimeseriesReading } from '@/services/api/models/MilkProductTimeseries';
import { FetchProductsCombinedGraphData } from '@/services/api/ProductApi';
import { Product } from '@/store/models/Product';
import '@/utils/RoundedBar.ts';
import Chart, {
  ChartConfiguration,
  ChartDataSets,
  ChartOptions
} from 'chart.js';
import dayjs from 'dayjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import groupBy from '@/utils/GroupBy';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventGraphModalOpen } from '@/services/ga/events/EventGraphModalOpen';
import { EventGraphChangeDate } from '@/services/ga/events/EventGraphChangeDate';
import ProductStore from '@/store/modules/ProductStore';

@Component
export default class CombinedVolumeGraph extends Vue {
  @Prop({ default: [] }) readonly colors!: Array<string>;
  @Prop() day!: string;
  @Prop() selectedVatId!: number;
  @Prop() selectedProduct!: Product;
  @Prop() location!: string;
  @Prop() disabled!: boolean;

  public endDay = this.day;
  public offset = 0;
  public view = 'Week';
  public timePeriod = '';
  public disable = true;
  public loading = true;
  public noData = false;
  public oldSize = window.innerWidth;
  private chart!: Chart;
  private graphData!: MilkProductTimeseriesReading[];
  public graphOptions = {
    cornerRadius: this.location == 'modal' ? 15 : 5,
    legend: {
      display: true,
      position: 'top',
      labels: {
        padding: 20
      }
    },
    tooltips: {
      enabled: !this.disabled,
      callbacks: {
        label: (tooltipItems: any) => {
          return tooltipItems.yLabel + ' L';
        }
      }
    },
    scales: {
      yAxes: [
        {
          display: !this.disabled,
          stacked: true,
          gridLines: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
            maxRotation: 0,
            minRotation: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  } as ChartOptions;

  @Prop({
    default: () => {
      return Chart.defaults.bar;
    }
  })
  mounted() {
    this.endDay = this.day;
    this.timePeriod = this.getTimePeriod();
    if (this.selectedVatId) {
      this.updateData(true);
    }
  }

  @Watch('selectedVatId')
  selectedUpdated() {
    this.offset = 0;
    this.endDay = this.day;
    this.timePeriod = this.getTimePeriod();
    this.disable =
      dayjs(this.endDay).format('YYYYMMDD') ==
      dayjs(this.day).format('YYYYMMDD');
    this.updateData(false);
  }

  public showvolGraphModal() {
    if (!this.disabled) {
      GA.event<EventGraphModalOpen>(
        this.$gtag,
        new EventGraphModalOpen(
          'milking-volumes',
          this.selectedProduct?.productId,
          this.selectedVatId,
          'vats'
        )
      );

      this.$root.$emit('bv::show::modal', 'volGraphModal', '#btnShow');
    }
  }

  public initChart() {
    if (this.graphData) {
      this.createChart({
        labels: this.getLabels(),
        datasets: this.getData()
      });
    } else {
      this.loading = true; //getting weird errors with the data not being loaded yet so length is 0?
    }
  }

  public initBlankChart() {
    this.createChart({
      labels: this.getBlankLabels(),
      datasets: [
        {
          label: 'Am',
          data: [],
          backgroundColor: this.colors[0]
        },
        {
          label: 'Pm',
          data: [],
          backgroundColor: this.colors[1]
        }
      ]
    });
    if (
      this.chart?.options?.scales?.yAxes &&
      this.chart?.options?.scales?.yAxes[0].ticks
    ) {
      //This sets the graph to display the vat capacity as the maximum when the graph is empty
      this.chart.options.scales.yAxes[0].ticks.suggestedMax = 1000;

      this.chart.update();
    }
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `bar-${this.selectedVatId}-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'bar',
        data: chartData,
        options: this.graphOptions
      };
      this.chart = new Chart(canvas as HTMLCanvasElement, options);
    }
  }

  public updateData(initialise: boolean) {
    this.loading = true;
    FetchProductsCombinedGraphData(
      this.selectedProduct.supplierNumber.toString(),
      this.selectedProduct.supplier?.toString(),
      this.selectedVatId.toString(),
      dayjs(this.startFromDate())
        .unix()
        .toString(),
      dayjs(this.endDay)
        .unix()
        .toString(),
      this.view == 'Week' ? 9 : 30,
      this.offset,
      this.disabled,
      'Combined Volume Graph'
    ).then(data => {
      if (data.length == 0) {
        this.noData = true;
        try {
          this.graphData = data;
          if (initialise || !this.chart) {
            this.initBlankChart();
          } else {
            this.chart.data.labels = this.getBlankLabels();
            this.chart.data.datasets = [
              {
                label: 'Am',
                data: [],
                backgroundColor: this.colors[0]
              },
              {
                label: 'Pm',
                data: [],
                backgroundColor: this.colors[1]
              }
            ];
            this.chart.options = this.graphOptions;
            if (
              this.chart?.options?.scales?.yAxes &&
              this.chart?.options?.scales?.yAxes[0].ticks
            ) {
              //This sets the graph to display the vat capacity as the maximum when the graph is empty
              this.chart.options.scales.yAxes[0].ticks.suggestedMax = 1000;
            }
            this.chart.update();
          }
        } catch (e) {
          this.toast(e);
        }
      } else {
        this.noData = false;
        try {
          this.graphData = data;
          if (initialise || !this.chart) {
            this.initChart();
          } else {
            this.chart.data.labels = this.getLabels();
            this.chart.data.datasets = this.getData();

            this.chart.options = this.graphOptions;
            this.chart.update();
          }
        } catch (e) {
          this.toast(e);
        }
      }
      this.loading = false;
    });
  }

  public getData(): ChartDataSets[] {
    const values = this.fetchProductsGraphSampleData();
    const grouped = Array.from(groupBy(values, (value: any) => value.day));
    const am = grouped.map((dayEntry: Array<any>) => {
      let vol = 0;
      dayEntry[1].forEach((value: any) => {
        if (!value.pm) {
          vol = Math.round(value.volume);
        }
      });
      return vol;
    });
    const pm = grouped.map((dayEntry: Array<any>) => {
      let vol = 0;
      dayEntry[1].forEach((value: any) => {
        if (value.pm) {
          vol = Math.round(value.volume);
        }
      });
      return vol;
    });

    return [
      {
        label: 'Am',
        data: am,
        backgroundColor: this.colors[0]
      },
      {
        label: 'Pm',
        data: pm,
        backgroundColor: this.colors[1]
      }
    ];
  }

  public getLabels(): string[] {
    const prod = Array.from(
      new Set(
        this.fetchProductsGraphSampleData().map((entry: any) => {
          return entry.date;
        })
      )
    );
    return prod as string[];
  }

  public getBlankLabels(): string[] {
    const labels = [];
    for (let i = 0; i <= (this.view == 'Week' ? 7 : 28); i++) {
      labels.push(
        this.startFromDate()
          .add(i, 'day')
          .format('DD')
      );
    }
    return labels;
  }

  public getTimePeriod(): string {
    return (
      this.startFromDate().format('DD MMM - ') +
      dayjs(this.endDay).format('DD MMM')
    );
  }

  public startFromDate() {
    return dayjs(this.endDay).subtract(this.view == 'Week' ? 7 : 28, 'day');
  }

  public changeTimePeriod(direction: string) {
    this.loading = true;
    const prevTimePeriod = {
      from: this.startFromDate().format(),
      to: dayjs(this.endDay).format()
    };
    if (direction == 'left') {
      this.endDay = this.startFromDate().format('YYYY-MM-DD');
      this.timePeriod = this.getTimePeriod();
    } else {
      this.endDay = dayjs(this.endDay)
        .add(this.view == 'Week' ? 7 : 28, 'day')
        .format('YYYY-MM-DD');

      this.timePeriod = this.getTimePeriod();
    }
    this.offset = dayjs().diff(dayjs(this.endDay), 'day');
    this.offset = this.offset == 0 ? 0 : this.offset - 1;
    this.disable =
      dayjs(this.endDay).format('YYYYMMDD') ==
      dayjs(this.day).format('YYYYMMDD');

    GA.event<EventGraphChangeDate>(
      this.$gtag,
      new EventGraphChangeDate(
        'milking-volume',
        ProductStore.currentProduct?.productId,
        this.selectedVatId,
        'vats',
        prevTimePeriod,
        {
          from: this.startFromDate().format(),
          to: dayjs(this.endDay).format()
        }
      )
    );

    this.updateData(false);
  }

  public changeView(newView: string) {
    this.loading = true;

    const prevTimePeriod = {
      from: this.startFromDate().format(),
      to: dayjs(this.endDay).format()
    };

    this.view = newView;
    this.endDay = this.day;
    this.offset = 0;
    this.timePeriod = this.getTimePeriod();

    GA.event<EventGraphChangeDate>(
      this.$gtag,
      new EventGraphChangeDate(
        'milking-volume',
        ProductStore.currentProduct?.productId,
        this.selectedVatId,
        'vats',
        prevTimePeriod,
        {
          from: this.startFromDate().format(),
          to: dayjs(this.endDay).format()
        }
      )
    );

    this.disable =
      dayjs(this.endDay).format('YYYYMMDD') ==
      dayjs(this.day).format('YYYYMMDD');
    this.updateData(false);
  }

  public fetchProductsGraphSampleData(): any {
    const products = this.graphData.map((entry: any) => {
      const day = dayjs.unix(entry.finishTime);
      return {
        date: day.format('DD'),
        volume: entry.value,
        day: +dayjs.unix(entry.finishTime).format('DD'),
        pm: day.isBefore(day.format('YYYY-MM-DD') + '12:00') ? 0 : 1
      };
    });
    return products;
  }

  public toast(e: any) {
    this.$bvToast.toast(`Graph not updated - ${e}`, {
      title: 'Internal error',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
  }
}
