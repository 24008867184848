
interface Sheet {
  name: string;
  data: Product[];
  bookType: 'xlsx' | 'csv';
}
import { Product } from '@/store/models/Product';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FromToDatePicker extends Vue {
  @Prop() collectionsDate!: { from: string; to: string };
  public max = dayjs().format('YYYY-MM-DD');

  get isFromBeforeTo() {
    return (
      this.collectionsDate.from == this.collectionsDate.to ||
      dayjs(this.collectionsDate.from).isBefore(dayjs(this.collectionsDate.to))
    );
  }
}
