
import ProductStore from '@/store/modules/ProductStore';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import Toast from '../common/Toast.vue';
import { UpdateEntityCustomSettingsData } from '@/services/api/CustomSettingApi';
import User from '@/store/modules/User';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';

@Component({
  components: { FormDropdownInput, Toast },
  computed: {
    currentProduct: () => ProductStore.currentProduct ?? undefined,
    currentEntity: () => ProductStore.currentEntity ?? undefined
  }
})
export default class UsageDayPatternSettings extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() disabled!: boolean;
  public usageDayPatternOptions = {
    5: { label: 'Monday to Friday', value: 5 },
    6: { label: 'Monday to Saturday', value: 6 },
    7: { label: 'Monday to Sunday', value: 7 }
  };
  public usageDayPattern = this.option;
  public updated = false;

  @Watch('$store.state.product._currentEntityIndex', { deep: true })
  @Watch('$store.state.product._currentProduct', { deep: true })
  private currentEntityUpdated() {
    this.usageDayPattern = this.option;
  }

  private get option() {
    return this.usageDayPatternOptions[
      (ProductStore.currentEntity
        ?.entityCustomSettingsData as FuelCustomSettingsData)
        ?.usageDayPattern ?? 7
    ];
  }

  public newInput() {
    this.updated = true;
  }

  public submitUpdateData() {
    UpdateEntityCustomSettingsData({
      partnerId: User.orgId,
      entityType: 'tanks',
      entityId: ProductStore.currentEntity?.entityId ?? 0,
      data: [
        {
          usageDayPattern: this.usageDayPattern.value
        }
      ]
    }).then(() => {
      this.updated = false;
      this.$bvToast.show('changesAppliedUsage');
      this.$root.$emit('customFieldsUpdated');
    });
  }
}
