
import ActivityDatePickerModal from '@/components/modal/ActivityDatePickerModal.vue';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import MilkActivityList from '../list/MilkActivityList.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: { MilkActivityList, ActivityDatePickerModal, SidebarCard }
})
export default class MilkActivityListCard extends mixins(AppName) {
  @Prop() selectedProduct!: Product;

  public showActivityDatePickerModal() {
    this.$root.$emit('bv::show::modal', 'activityDatePickerModal', '#btnShow');
  }

  public closeActivityDatePickerModal() {
    this.$root.$emit('bv::hide::modal', 'activityDatePickerModal', '#btnHide');
  }
}
