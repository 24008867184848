
import MilkScore, { ComplianceSettings } from '@/store/models/MilkScore';
import { MilkMetrics } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import { toTitleCase } from '@/utils/FormattingUtils';
import {
  complianceMethodRanges,
  getComplianceScore,
  getMethod
} from '@/utils/MilkScoreUtils';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import MilkScoreTooltip from './MilkScoreTooltip.vue';

@Component({
  components: {
    MilkScoreTooltip
  },
  methods: {
    toTitleCase
  }
})
export default class MilkScoreScale extends mixins(AppName) {
  public thresholds = [0, 1000, 2500, 4000, 5000];
  public colours = ['green', 'yellow', 'orange', 'red'];
  public scaleWidth = 323;
  public currentScore: MilkScore | null = null;
  public thresholdRange = [0, 0];

  get currentEntity(): any | null {
    return ProductStore.currentEntity ?? null;
  }

  // If data is broken and milkScores and milkScore are both present, Fonterra takes priority
  get isFonterra(): boolean {
    return this.currentEntity?.supplier == 'F';
  }

  get getCurrentLevel(): string {
    return this.isFonterra
      ? this.subTitles
        ? this.subTitles[this.fonterraMilkScore]
        : 'CURRENT'
      : '-';
  }

  get fonterraMilkScore(): number {
    return this.currentEntity?.metrics.milkScore;
  }

  get subTitles(): string[] {
    if (this.isFonterra) {
      return (
        CustomStore.groupedComplianceMethods
          ?.get('fonterra')
          ?.settings?.ranges.map(obj => obj.label.replace('Risk', '')) ??
        this.colours
      );
    }
    return this.colours;
  }

  @Watch('currentEntity')
  newEntity(): void {
    this.updateCurrentScore();
    this.updateThresholdRange();
    this.scaleWidth =
      document.getElementById('colourScale')?.clientWidth ?? 323;
  }

  mounted(): void {
    this.scaleWidth =
      document.getElementById('colourScale')?.clientWidth ?? 323;

    this.updateCurrentScore();
    this.updateThresholdRange();
  }

  private updateCurrentScore(): void {
    this.currentScore = getComplianceScore(
      (this.currentEntity?.metrics as MilkMetrics).milkScores,
      this.currentEntity?.entityCustomSettingsData as ComplianceSettings
    );
  }

  private updateThresholdRange(): void {
    const thresholdRanges = complianceMethodRanges(
      getMethod(
        this.currentEntity?.entityCustomSettingsData as ComplianceSettings
      )
    );
    if (thresholdRanges?.length) {
      this.thresholds = thresholdRanges;
    }
    if (this.currentScore?.rating != undefined) {
      this.thresholdRange = this.thresholds.slice(
        this.getCurrentScoreBlock(),
        this.getCurrentScoreBlock() + 2
      );
    } else if (this.isFonterra) {
      this.thresholdRange = [
        this.fonterraMilkScore,
        this.fonterraMilkScore + 1
      ];
    } else {
      this.thresholdRange = [0, 0];
    }
  }

  public getScaleWidth(id: string): number {
    return document.getElementById(id)?.clientWidth ?? 70;
  }

  public textAlign(index: number): string {
    const midPoint = (this.thresholds.length - 1) / 2;
    if (index == midPoint) {
      return 'text-center';
    } else {
      return index > midPoint ? 'text-right' : 'text-left';
    }
  }

  public isCurrentScoreBlock(index: number): boolean {
    return (
      index == this.getCurrentScoreBlock() ||
      (index == 3 && this.getCurrentScoreBlock() == 4)
    );
  }

  public getCurrentScoreBlock(): number {
    if (this.isFonterra) {
      if (this.fonterraMilkScore >= 4) {
        return 4;
      }
      return this.fonterraMilkScore;
    } else if (this.currentScore?.rating) {
      if (this.currentScore?.rating > 3) {
        return 3;
      }
      return this.currentScore?.rating;
    } else {
      return 0;
    }
  }
}
