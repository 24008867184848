var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('EntitySidebarHeader',{attrs:{"entityTitles":_vm.entityTitles,"size":"large"},on:{"prevEntity":_vm.prevEntity,"nextEntity":_vm.nextEntity,"specificEntity":_vm.specificEntity}}),(_vm.currentEntity && (_vm.isNotLive || _vm.currentEntity.isNew))?_c('SidebarWarning',{attrs:{"colour":_vm.isNotLive ? 'secondary' : _vm.getAppColour(true),"icon":!_vm.isNotLive ? 'star-fill' : undefined,"text":!_vm.isNotLive
        ? `New ${_vm.getEntityName(false, _vm.currentEntity)}: This ${_vm.getEntityName(
            false,
            _vm.currentEntity
          )} recently went live ${
            _vm.currentEntity.liveAt
              ? `on the ${_vm.getDate(_vm.currentEntity.liveAt)}`
              : ''
          }`
        : undefined}}):_vm._e(),_c('FaultImpactWarning'),_c('MetricTileGroup',{attrs:{"width":_vm.width}}),_c('EntityGraphsGroup',{attrs:{"isNotLive":_vm.isNotLive,"title":_vm.entityTitles.title}}),(
      !_vm.isNotLive && !_vm.isApp(_vm.PRODUCT_TYPE_MILK) && !_vm.isApp(_vm.PRODUCT_TYPE_WATER)
    )?_c('ActivityListCard',{attrs:{"title":_vm.entityTitles.title}}):_vm._e(),_c('DetailsCard',{attrs:{"siteView":false}}),_c('p',{staticClass:"text-mediumGrey text-left mx-2 pt-3"},[_vm._v(" Levno Internal Entity Reference: "+_vm._s(_vm.currentEntity ? _vm.currentEntity.entityId : '-')+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }