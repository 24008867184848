
import {
  FetchProductsCombinedEventsData,
  FetchVatWashData
} from '@/services/api/ProductApi';
import { Product } from '@/store/models/Product';
import { datesToDays, datesToOffset } from '@/utils/DateUtils';
import { formatMilkVolume } from '@/utils/TableFormatters';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class MilkActivityList extends mixins(AppName) {
  @Prop() selectProdId!: number;
  @Prop() selectedProduct!: Product;
  @Prop() location!: string;

  private events = [{}];
  public noEvents = true;
  public loading = true;

  created() {
    this.$root.$on('activityUpdated', this.getEvents);
  }

  mounted() {
    if (this.selectedProduct && this.selectedProduct.supplierNumber) {
      this.getEvents();
    }
  }

  @Watch('selectProdId')
  updateEvents() {
    if (this.selectedProduct && this.selectedProduct.supplierNumber) {
      this.getEvents();
    }
  }

  public getEvents(
    from = dayjs()
      .subtract(3, 'day')
      .unix()
      .toString(),
    to = dayjs()
      .unix()
      .toString()
  ) {
    this.loading = true;
    this.noEvents = true;
    FetchProductsCombinedEventsData(
      this.selectedProduct.supplierNumber.toString(),
      this.selectedProduct.supplier?.toString(),
      from,
      to,
      datesToDays(+to, +from),
      datesToOffset(+to),
      'milking',
      'Milk Activity List - milkings'
    ).then(milkings => {
      const milkingFinish = milkings.map(milking => {
        return {
          eventType:
            'Milking at ' +
            dayjs.unix(milking.startTime).format('h.mma') +
            ': ',
          value: formatMilkVolume(milking.value.toString()).toLocaleString(),
          timestamp: milking.finishTime
        };
      });
      FetchProductsCombinedEventsData(
        this.selectedProduct.supplierNumber.toString(),
        this.selectedProduct.supplier?.toString(),
        from,
        to,
        datesToDays(+to, +from),
        datesToOffset(+to),
        'pickup',
        'Milk Activity List - pickups'
      ).then(pickups => {
        const pickupFinish = pickups.map(pickup => {
          return {
            eventType: 'Collection: ',
            value: formatMilkVolume(pickup.value.toString()).toLocaleString(),
            timestamp: pickup.finishTime
          };
        });
        let events = milkingFinish.concat(pickupFinish);
        const vatsToCollectData: Promise<any>[] = [];
        this.selectedProduct.entities?.forEach(vat => {
          vatsToCollectData.push(
            FetchVatWashData(
              vat.entityId.toString(),
              from,
              to,
              4,
              0,
              vat.name,
              'Milk Activity List - vat washes'
            )
          );
        });
        Promise.all(vatsToCollectData).then(vats => {
          const combinedWashEvents: any[] = [];
          vats.forEach(vat => {
            vat.data.vatTempEvents.forEach((wash: any) => {
              combinedWashEvents.push({
                eventType: `${this.getEntityName(true)} Wash: `,
                value: vat.name,
                timestamp: wash.timestamp / 1000
              });
            });
          });
          events = events.concat(combinedWashEvents);
          events.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
          if (events.length == 0) {
            this.noEvents = true;
          } else {
            this.noEvents = false;
            this.events = events;
          }
          this.$root.$emit('activityListLoaded');
          this.loading = false;
        });
      });
    });
  }
}
