var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentEntity)?_c('div',{style:(` --colour: var(--${_vm.getAppColour(true)});
    --darkerColour: var(--dark${_vm.getAppColour(true)}); 
    --sliderWidth: ${_vm.sliderWidth ? _vm.sliderWidth : '200'}px`)},[_c('p',{staticClass:"settingsLabel px-3"},[_vm._v("Low-Level Alerts Overrides")]),_c('BForm',{staticClass:"p-3",style:(_vm.disabled ? 'opacity: 0.5' : ''),on:{"submit":function($event){$event.preventDefault();return _vm.submitUpdateData.apply(null, arguments)}}},[_c('BFormRadioGroup',{staticClass:"d-flex align-items-start mb-3 text-medium",attrs:{"id":"percent-litres"},model:{value:(_vm.showPercent),callback:function ($$v) {_vm.showPercent=$$v},expression:"showPercent"}},[_c('BFormRadio',{staticStyle:{"z-index":"0"},attrs:{"value":true,"disabled":_vm.disabled}},[_vm._v("Percent")]),_c('BFormRadio',{staticStyle:{"z-index":"0"},attrs:{"value":false,"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.toTitleCase(_vm.getUnitWord(_vm.currentEntity, true))))])],1),_c('label',{class:`inputLabel lowLevelLabel text-${_vm.getAppColour(
          true
        )} pl-3 text-primary`,attrs:{"for":"lowLevelBox"}},[_c('span',{staticClass:"pl-3"},[_vm._v("Low Level")])]),_c('div',{staticClass:"lowLevelInput pt-3 px-3 mb-3",attrs:{"id":"lowLevelBox"}},[_c('BRow',[_c('BCol',{staticClass:"pl-3 pr-0",staticStyle:{"padding-top":"5px"},attrs:{"cols":"3"}},[_c('p',{staticClass:"lowLevelValue"},[_vm._v(" "+_vm._s(_vm.formatValue(_vm.lowValue))),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.showPercent ? '%' : _vm.getUnitWithTonnes(_vm.lowValue, _vm.currentEntity)))])])]),_c('BCol',{staticStyle:{"padding-top":"4px"},attrs:{"cols":"8"}},[_c('b-form-slider',{ref:"basic",class:_vm.criticalPercentVal > _vm.lowPercentVal
                ? 'lowLevelSlider errorSlider'
                : 'lowLevelSlider',attrs:{"step":5,"min":0,"max":100,"disabled":_vm.disabled,"trigger-change-event":""},on:{"change":_vm.newLowInput},model:{value:(_vm.lowPercentVal),callback:function ($$v) {_vm.lowPercentVal=$$v},expression:"lowPercentVal"}})],1)],1)],1),_c('label',{class:`inputLabel lowLevelLabel text-${_vm.getAppColour(
          true
        )} pl-3 text-primary`,attrs:{"for":"criticalLevelBox"}},[_c('span',{staticClass:"pl-3"},[_vm._v("Critical Level")])]),_c('div',{staticClass:"lowLevelInput pt-3 px-3 mb-3",attrs:{"id":"criticalLevelBox"}},[_c('BRow',[_c('BCol',{staticClass:"pl-3 pr-0",staticStyle:{"padding-top":"5px"},attrs:{"cols":"3"}},[_c('p',{staticClass:"lowLevelValue"},[_vm._v(" "+_vm._s(_vm.formatValue(_vm.criticalValue))),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.showPercent ? '%' : _vm.getUnitWithTonnes(_vm.lowValue, _vm.currentEntity)))])])]),_c('BCol',{ref:"critBox",staticStyle:{"padding-top":"4px"},attrs:{"cols":"8"}},[_c('div',{staticClass:"position-relative",attrs:{"id":"yeet"}},[_c('b-form-slider',{ref:"basic",class:_vm.criticalPercentVal > _vm.lowPercentVal
                  ? 'lowLevelSlider errorSlider'
                  : 'lowLevelSlider',attrs:{"step":5,"min":0,"max":100,"disabled":_vm.disabled,"trigger-change-event":""},on:{"change":_vm.newCriticalInput},model:{value:(_vm.criticalPercentVal),callback:function ($$v) {_vm.criticalPercentVal=$$v},expression:"criticalPercentVal"}})],1)])],1)],1),(_vm.error != '')?_c('p',{staticClass:"p-0 text-danger"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.updated && !_vm.disabled)?_c('BButton',{attrs:{"type":"submit","variant":_vm.getAppColour(true),"disabled":_vm.lowPercentVal < _vm.criticalPercentVal},on:{"click":_vm.submitUpdateData}},[_vm._v("Save Changes")]):_vm._e(),((!_vm.isDefaultLow || !_vm.isDefaultCritical) && !_vm.updated && _vm.isAdmin())?_c('BButton',{directives:[{name:"b-modal",rawName:"v-b-modal.resetToDefault",modifiers:{"resetToDefault":true}}],staticClass:"w-100 text-medium",attrs:{"variant":"lightGrey"}},[_c('BIcon',{attrs:{"icon":"arrow-counterclockwise"}}),_vm._v(" Reset tank to default alert levels")],1):_vm._e()],1),_c('Toast',{attrs:{"id":"changesApplied","title":"Alert levels updated"}},[_c('p',[_vm._v(" Changes made to tank level ")]),(_vm.error != '')?_c('p',{staticClass:"p-0 text-danger"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('Toast',{attrs:{"id":"changesUndone","title":"Alert levels changes undone"}},[_c('p',[_vm._v("Changes made to tank alert levels have been undone.")])]),_c('ConfirmationModal',{attrs:{"id":"resetToDefault","title":"Are you sure you want to reset tank levels to the default alert values?"},on:{"confirm":_vm.resetDefault}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }