import { GAEvent } from '@/services/ga/events/GAEvent';
import { DateRange } from '@/services/ga/models/DateRange';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventSidebarActivityListChangeDate extends GAEvent {
  public eventName = 'sidebar-activity-list-change-date';
  public productId?: number;
  public entityId?: number;
  public entityType?: EntityApiType;
  public prevDate?: DateRange;
  public nextDate?: DateRange;

  constructor(
    productId?: number,
    entityId?: number,
    entityType?: EntityApiType,
    prevDate?: DateRange,
    nextDate?: DateRange
  ) {
    super();
    this.productId = productId;
    this.entityId = entityId;
    this.entityType = entityType;
    this.prevDate = prevDate;
    this.nextDate = nextDate;
  }
}
