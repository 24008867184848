
import { Product } from '@/store/models/Product';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import CombinedVolumeGraph from '../graphs/CombinedVolumeGraph.vue';
import AppName from '../mixin/AppName.vue';
import CombinedVolumeGraphModal from '../modal/CombinedVolumeGraphModal.vue';

@Component({
  components: { CombinedVolumeGraph, CombinedVolumeGraphModal, SidebarCard },
  methods: { getDayjsFromUnix, getToday }
})
export default class CombinedVolumeGraphCard extends mixins(AppName) {
  @Prop() currentProduct!: Product;
  @Prop() isNotLive!: boolean;
}
