import { GAEvent } from '@/services/ga/events/GAEvent';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventSidebarNavigate extends GAEvent {
  public eventName = 'sidebar-navigate';
  public productId?: number;
  public tab?: string;

  constructor(productId?: number, tab?: string) {
    super();
    this.productId = productId;
    this.tab = tab;
  }
}
