var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BCard',{staticClass:"sidebarTileCard card-body-px-0 rounded px-0 mb-1",class:[
    _vm.width < 576
      ? 'mx-0'
      : _vm.tileElements.isLeftTile
      ? 'mr-1'
      : _vm.tileElements.isLeftTile == null
      ? ''
      : 'ml-1',
    _vm.classAlterations.card,
    { 'text-mediumGrey': _vm.disabled }
  ],attrs:{"id":_vm.tileElements.title,"border-variant":!_vm.disabled && _vm.classAlterations.borderVariant
      ? _vm.classAlterations.borderVariant
      : ''}},[_c('BCardText',{class:_vm.classAlterations.cardText},[_c('p',{staticClass:"detailCardP pb-3",class:_vm.classAlterations.title,staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.tileElements.title)+" ")]),(_vm.tileElements.icon)?_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.getImage(_vm.tileElements.icon),"width":"40","height":"40","alt":`${_vm.getAppName(false)} ${_vm.tileElements.icon}`}}):_c('BIcon',{staticClass:"my-auto",attrs:{"icon":"arrow-clockwise","scale":"2"}}),_c('div',[_c('h2',{staticClass:"mb-0",class:_vm.classAlterations.value},[_vm._v(" "+_vm._s(_vm.disabled ? '-' : `${_vm.tileElements.value.toLocaleString()}${ _vm.tileElements.unit ? _vm.tileElements.unit : '' }`)+" "),(
            _vm.tileElements.secondaryValue != '' &&
              _vm.tileElements.secondaryValue != undefined &&
              !_vm.disabled
          )?_c('span',{class:_vm.classAlterations.secondaryValue,staticStyle:{"font-size":"16px"}},[_vm._v("("+_vm._s(_vm.tileElements.secondaryValue)+")")]):_vm._e()]),(_vm.tileElements.subtitleValue)?_c('p',{staticClass:"detailCardP pt-0 text-mediumGrey",class:_vm.classAlterations.subtitleValue},[(!_vm.disabled)?_c('span',[_vm._v(_vm._s(_vm.tileElements.subtitleValue))]):_c('span',[_vm._v("-")])]):_c('p',{staticClass:"detailCardP pt-0 text-mediumGrey",class:_vm.classAlterations.subtitleValue},[(!_vm.disabled)?_vm._t("default"):_c('span',[_vm._v("-")])],2)]),(_vm.disabled && _vm.tileElements.disabledMessage)?_c('BTooltip',{staticClass:"disabledTooltip",attrs:{"target":_vm.tileElements.title,"triggers":"hover","placement":"bottom","variant":"mediumGrey"}},[_vm._v(" "+_vm._s(_vm.tileElements.disabledMessage)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }