
import FeedLevel from '@/services/api/models/FeedLevel';
import { FetchFeedRefillGraphData } from '@/services/api/ProductApi';
import { FeedMetrics, Product } from '@/store/models/Product';
import { getPreferredMetricWord } from '@/utils/AppName';
import { datesToDays, datesToOffset } from '@/utils/DateUtils';
import { getFormattedFeedAmount } from '@/utils/FeedUtils';
import groupBy from '@/utils/GroupBy';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({ methods: { getFormattedFeedAmount } })
export default class FeedRefillsList extends mixins(AppName, ConstantsMixin) {
  @Prop() selectProd!: Product | null;
  @Prop() tankId!: number;

  private events: {
    eventType: string;
    timestamp: number;
    value: number;
  }[] = [];
  public noEvents = true;
  public loading = true;

  created() {
    this.$root.$on('activityUpdated', this.getEvents);
  }

  mounted() {
    if (this.selectProd && this.selectProd.entityId) {
      this.getEvents();
    }
  }

  @Watch('selectProd')
  updateEvents() {
    this.getEvents();
  }

  public getEvents(
    from = dayjs()
      .subtract(30, 'day')
      .unix()
      .toString(),
    to = dayjs()
      .unix()
      .toString()
  ) {
    if (this.selectProd) {
      const selectedId =
        this.tankId != -1 ? this.tankId : this.selectProd.entityId;
      if (selectedId) {
        this.noEvents = true;
        this.loading = true;
        FetchFeedRefillGraphData(
          selectedId.toString(),
          datesToDays(+to, +from),
          datesToOffset(+to),
          'Feed Refills List'
        ).then(values => {
          if (!values || values.length == 0) {
            this.noEvents = true;
            this.loading = false;
          } else {
            this.noEvents = false;

            const groupedFills = this.groupDays(values ?? []);
            const fills = groupedFills.map(event => {
              return {
                eventType: 'Fill ',
                value: Math.round(this.getRefillMetric(event)),
                timestamp: dayjs(event.date).unix()
              };
            });

            fills.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
            this.events = fills;
          }
          this.loading = false;
        });
      }
    }
  }

  public groupDays(events: FeedLevel[]) {
    const grouped = Array.from(
      groupBy(events, (value: any) => dayjs(value.date).format('D-MM-YYYY'))
    );
    const sumValues: FeedLevel[] = [];
    grouped.forEach(dayList => {
      if (dayList[1].length > 1) {
        let totalValue = 0;
        dayList[1].forEach((element: FeedLevel) => {
          totalValue += this.getRefillMetric(element);
        });
        sumValues.push({
          ...dayList[1][0],
          value: totalValue,
          weight: totalValue
        });
      } else if (dayList[1].length == 1) {
        sumValues.push({ ...dayList[1][0] });
      }
    });
    return sumValues;
  }

  public getRefillMetric(event: FeedLevel) {
    return getPreferredMetricWord(
      (this.selectProd?.metrics as FeedMetrics)?.feedTypeLevelMetric
    ) == this.METRIC_VOLUME
      ? event.value
      : event.weight ?? event.value;
  }
}
