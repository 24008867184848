import { GAEvent } from '@/services/ga/events/GAEvent';
import { DateRange } from '@/services/ga/models/DateRange';

export class EventSidebarMilkscoreGraphPreview extends GAEvent {
  public eventName = 'sidebar-milkscore-graph-preview';
  public productId?: number;
  public date?: DateRange;

  constructor(productId?: number, date?: DateRange) {
    super();
    this.productId = productId;
    this.date = date;
  }
}
