
import {
  VatMilkScoreTimeseriesReading,
  VatTimeseriesReading
} from '@/services/api/models/VatTimeseries';
import {
  FetchMilkScoreHistory,
  FetchVatGraphData
} from '@/services/api/ProductApi';
import { ComplianceSettings } from '@/store/models/MilkScore';
import { MilkMetrics, Product } from '@/store/models/Product';
import { Constants, getBootstrapColourHex } from '@/utils/Constants';
import {
  formatMilkingHoldingData,
  milkingTimeBackground
} from '@/utils/GraphUtils';
import { complianceMethodRanges, getMethod } from '@/utils/MilkScoreUtils';
import '@/utils/RoundedBar.ts';
import { sortByTimestamp } from '@/utils/SortingUtils';
import { formatMilkScoreColour } from '@/utils/TableFormatters';
import Chart, { ChartConfiguration, ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class MilkScoreGraph extends mixins(AppName, ConstantsMixin) {
  @Prop() day!: string;
  @Prop() selectedVatId!: number;
  @Prop() selectedEntity!: Product;
  @Prop() location!: string;
  @Prop() disabled!: boolean;
  @Prop() timePeriod!: { from: string; to: string };
  @Prop() modalPeriod!: number;

  public disable = true;
  public loading = true;
  public noData = false;
  public oldSize = window.innerWidth;
  private chart!: Chart;
  private graphData!: VatTimeseriesReading[];
  public milkScores: string[] = [];
  public scoreData: VatMilkScoreTimeseriesReading[] = [];
  public volumeData: { timestamp: number; value: number }[] = [];
  public maxScore = 0;

  public colors = ['#999999', '#CCCCCC'];

  get options() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 5,
          right: 20
        }
      },
      legend: {
        display: false,
        labels: {
          font: {
            family: 'Sofia Pro'
          }
        }
      },
      tooltips: {
        enabled: false,
        custom: this.processTooltipModel,
        intersect: false,
        mode: 'index'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: !this.disabled,
              source: 'data'
            },
            afterFit: function(scaleInstance) {
              scaleInstance.width = 50;
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              display: !this.disabled,
              autoSkip: true,
              maxTicksLimit: this.location == 'sidebar' ? 4 : 12,
              source: 'labels'
            },
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'h:mma'
              }
            }
          }
        ]
      },
      elements: {
        point: {
          pointRadius: 0
        },
        line: {
          tension: 0
        }
      },
      plugins: {
        datalabels: {
          color: '#F6861F',
          backgroundColor: '#e9ecef',
          borderRadius: 4,
          align: 'end',
          offset: 10,
          padding: 5,
          opactiy: 0.5
        }
      }
    } as ChartOptions;
  }

  mounted() {
    if (this.selectedVatId) {
      this.updateData();
    }
  }

  @Watch('timePeriod')
  @Watch('selectedVatId')
  triggerUpdate() {
    this.updateData();
  }

  public showvolScoreGraphModal() {
    this.$emit('openVolScoreModal');
  }

  public initChart() {
    Chart.pluginService.register(ChartDataLabels);
    if (this.scoreData) {
      const data = this.getData();
      this.createChart(data);
    } else {
      this.loading = true;
    }
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `linegraph-${this.selectedVatId}-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'line',
        data: chartData,
        options: this.options,
        plugins: [milkingTimeBackground]
      };
      this.chart = new Chart(canvas as HTMLCanvasElement, options);
      this.updateYAxes();
      this.chart.update();
    }
  }

  public updateYAxes() {
    const method = getMethod(
      this.selectedEntity?.entityCustomSettingsData as ComplianceSettings
    );
    const ranges = complianceMethodRanges(method);
    const roundingNum =
      method == Constants.COMPLIANCE_METHOD_SAMR && this.maxScore < 100
        ? 10
        : 100;
    const max =
      ranges[4] < this.maxScore
        ? Math.ceil(this.maxScore / roundingNum) * roundingNum
        : ranges[4];
    if (
      this.chart?.options?.scales?.yAxes &&
      this.chart?.options?.scales?.yAxes[0].ticks &&
      (ranges || method == Constants.COMPLIANCE_METHOD_SAMR)
    ) {
      this.chart.options.scales.yAxes[0].ticks.stepSize =
        method == Constants.COMPLIANCE_METHOD_SAMR
          ? Math.ceil(max / 5)
          : roundingNum;
      this.chart.options.scales.yAxes[0].ticks.maxTicksLimit =
        method == Constants.COMPLIANCE_METHOD_SAMR ? 5 : max / 10;
      this.chart.options.scales.yAxes[0].ticks.max = max;
      this.chart.options.scales.yAxes[0].ticks.min = 0;
      if (method != Constants.COMPLIANCE_METHOD_SAMR) {
        this.chart.options.scales.yAxes[0].ticks.callback = function(value) {
          return ranges.includes(+value) || max == +value ? value : null;
        };
      } else {
        {
          this.chart.options.scales.yAxes[0].ticks.callback = function(value) {
            return value;
          };
        }
      }
    }
  }

  public updateData() {
    this.loading = true;

    FetchVatGraphData(
      this.selectedVatId.toString(),
      dayjs(this.timePeriod.from)
        .unix()
        .toString(),
      dayjs(this.timePeriod.to)
        .unix()
        .toString(),
      this.disabled,
      'Milk Score Graph'
    ).then(data => {
      FetchMilkScoreHistory(
        this.selectedVatId.toString(),
        dayjs(this.timePeriod.from)
          .unix()
          .toString(),
        dayjs(this.timePeriod.to)
          .unix()
          .toString(),
        this.selectedEntity.entityCustomSettingsData as ComplianceSettings,
        this.disabled
      ).then(scores => {
        if (
          getMethod(
            this.selectedEntity.entityCustomSettingsData as ComplianceSettings
          ) == Constants.COMPLIANCE_METHOD_FONTERRA
        ) {
          this.scoreData = scores.map(score => {
            return {
              timestamp: score.timestamp,
              milkScore: score.milkScore,
              areaOutOfSpec: score.milkScore
            };
          });
        } else {
          this.scoreData = scores;
        }

        try {
          this.volumeData = sortByTimestamp(data.volume);
          this.graphData = sortByTimestamp(data.volumeEvents);
          if (!this.chart) {
            this.initChart();
          } else {
            const data: ChartData = this.getData();
            this.chart.options = this.options;
            this.chart.data = data;
            this.updateYAxes();
            this.chart.update();
          }
        } catch (e) {
          this.toast(e);
        }
        this.loading = false;
      });
    });
  }

  public getData(): ChartData | any {
    const values = formatMilkingHoldingData(
      this.timePeriod,
      this.day,
      this.location,
      this.modalPeriod,
      this.graphData,
      this.volumeData,
      this.selectedEntity
    );
    let scores = null;
    if (this.scoreData && this.scoreData.length != 0) {
      scores = this.formatGraphData();
    } else {
      this.noData = true;
    }

    return {
      datasets: [
        {
          label: 'Milking',
          data: values.milkingData,
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          datalabels: {
            display: false
          }
        },
        {
          label: '',
          data: scores ? scores.crossThreshold : [],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          pointRadius: 4,
          pointBackgroundColor: function(value: any, context: any) {
            return getBootstrapColourHex(
              formatMilkScoreColour(
                value.dataset.data[value.dataIndex].score,
                true
              )
            );
          },
          labels: this.milkScores,
          datalabels: {
            labels: {
              value: {
                align: 'end',
                anchor: 'end',
                clamp: true,
                color: '#FFFFFF',
                font: { family: 'Sofia Pro' },
                padding: { top: 4, left: 4, right: 4, bottom: 2 },
                backgroundColor: function(value: any, context: any) {
                  return getBootstrapColourHex(
                    formatMilkScoreColour(
                      value.dataset.data[value.dataIndex].score,
                      true
                    )
                  );
                },
                borderRadius: 3,
                formatter: function(value: any, context: any) {
                  return formatMilkScoreColour(value.score, true).toUpperCase();
                }
              }
            }
          }
        },
        {
          label: 'scores',
          data: scores ? scores.scores : [],
          borderColor: '#666666',
          backgroundColor: function(value: any, context: any) {
            return value.dataset.data[value.dataIndex]
              ? getBootstrapColourHex(
                  formatMilkScoreColour(
                    value.dataset.data[value.dataIndex].score,
                    true
                  )
                )
              : 'transparent';
          },
          pointRadius: 0,
          datalabels: {
            display: false
          }
        }
      ],
      labels: values.labels
    };
  }

  public formatGraphData() {
    const crossThresholdTooltips: { x: Date; y: number; score: number }[] = [];
    //When the vat becomes empty the score needs to go back to 0 at the time of pickup
    const scoreDataAlterations = this.graphData
      .filter(entry => entry.label == 'pickupFinish' && entry.type == 'full')
      .map(pickup => {
        return {
          timestamp: pickup.timestamp / 1000,
          milkScore: 0,
          areaOutOfSpec: 0
        };
      });
    if (
      this.scoreData[0] &&
      this.scoreData[0].areaOutOfSpec == 0 &&
      this.graphData[0]
    ) {
      scoreDataAlterations.push({
        timestamp: this.graphData[0].timestamp / 1000,
        milkScore: 0,
        areaOutOfSpec: 0
      });
    }
    if (
      this.scoreData[this.scoreData.length - 1] &&
      this.scoreData[this.scoreData.length - 1].areaOutOfSpec == 0 &&
      this.graphData[this.graphData.length - 1]
    ) {
      scoreDataAlterations.push({
        timestamp: this.graphData[this.graphData.length - 1].timestamp / 1000,
        milkScore: 0,
        areaOutOfSpec: 0
      });
    }
    let prevMilkScore: number | null = null;
    const result = sortByTimestamp([...this.scoreData, ...scoreDataAlterations])
      .filter((entry: any) => {
        return (
          entry != null &&
          entry.timestamp <=
            (this.selectedEntity?.metrics as MilkMetrics)?.milkScoresTs
        );
      })
      .map((entry: any) => {
        if (this.maxScore < +entry.areaOutOfSpec) {
          this.maxScore = Math.ceil(+entry.areaOutOfSpec);
        }
        if (
          +entry.areaOutOfSpec >= 1 &&
          (prevMilkScore == null || +entry.milkScore > prevMilkScore)
        ) {
          crossThresholdTooltips.push({
            x: new Date(entry.timestamp * 1000),
            y: Math.ceil(+entry.areaOutOfSpec),
            score: Math.ceil(+entry.milkScore)
          });
          prevMilkScore = +entry.milkScore;
        } else if (
          (prevMilkScore != null && +entry.milkScore < prevMilkScore) ||
          +entry.areaOutOfSpec == 0
        ) {
          prevMilkScore = null;
        }
        return {
          x: new Date(entry.timestamp * 1000),
          y: Math.ceil(+entry.areaOutOfSpec),
          score: +entry.milkScore
        };
      });

    this.noData = result.length == 0;
    return { scores: result, crossThreshold: crossThresholdTooltips };
  }

  public processTooltipModel(model: any) {
    if (!model.body) {
      return;
    }

    const tooltip = document.getElementById(`tooltip-${this.location}`);
    if (tooltip) {
      let index = 0;
      if (model.dataPoints?.length > 1) {
        index =
          model.dataPoints.findIndex((point: any) => {
            return point.label == model.title[0];
          }) ?? 0;
      }
      const colour = model.labelColors[index]?.backgroundColor ?? '#fff';
      if (colour != 'transparent') {
        const x = model.dataPoints?.length
          ? model.dataPoints[index].x
          : model.caretX;
        if (x < 90 && this.location == 'sidebar') {
          tooltip.style.left = '90px';
        } else if (x > 260 && this.location == 'sidebar') {
          tooltip.style.left = '260px';
        } else {
          tooltip.style.left = x + 'px';
        }
        tooltip.style.display = 'block';
        tooltip.style.top = '-52px';
        const line = document.getElementById(`tooltip-line-${this.location}`);
        if (line) {
          line.style.left = x + 'px';
        }
        const label = tooltip.querySelector('.tooltip-label');
        if (label && model.dataPoints?.length) {
          label.textContent = dayjs(model.dataPoints[index].label).format(
            'h:mma D MMM'
          );
          const unit = tooltip.querySelector(
            '.tooltip-value .unit'
          ) as HTMLElement;
          const value = tooltip.querySelector(
            '.tooltip-value .value'
          ) as HTMLElement;
          if (value && unit) {
            unit.style.color = colour;
            value.style.color = colour;
            value.textContent = model.dataPoints[index].value;
          }
        }
      }
    }
  }

  public showTooltip() {
    const line = document.getElementById(`tooltip-line-${this.location}`);
    if (line) {
      line.style.display = 'block';
    }
    const tooltip = document.getElementById(`tooltip-${this.location}`);
    if (tooltip) {
      tooltip.style.display = 'block';
    }
  }

  public hideTooltip() {
    const line = document.getElementById(`tooltip-line-${this.location}`);
    if (line) {
      line.style.display = 'none';
    }
    const tooltip = document.getElementById(`tooltip-${this.location}`);
    if (tooltip) {
      tooltip.style.display = 'none';
    }
  }

  public toast(e: any) {
    this.$bvToast.toast(`Graph not updated - ${e}`, {
      title: 'Internal error',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
  }
}
