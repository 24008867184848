
import FeedBalance from '@/services/api/models/FeedBalance';
import { MeterTimeseriesReading } from '@/services/api/models/MeterTimeseries';
import { TankTimeseriesReading } from '@/services/api/models/TankTimeseries';
import { UsageTimeseries } from '@/services/api/models/UsageTimeseries';
import { trackEvent } from '@/services/Mixpanel';
import ProductStore from '@/store/modules/ProductStore';
import {
  getEndOfDay,
  getEndOfMonth,
  getStartOfDay,
  getStartOfMonth,
  getStartOfWeek,
  getWeekOfYear
} from '@/utils/DateUtils';
import { formatFuelFeedValue, roundFuelFeedValue } from '@/utils/GraphUtils';
import '@/utils/RoundedBar.ts';
import Chart, { ChartConfiguration, ChartOptions } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventGraphModalOpen } from '@/services/ga/events/EventGraphModalOpen';
import { EventGraphChangeDate } from '@/services/ga/events/EventGraphChangeDate';
import { cloneDeep } from 'lodash';
import { getObjectItem } from '@/utils/LocalStorageUtils';

@Component
export default class UsageChart extends mixins(AppName, ConstantsMixin) {
  @Prop({ default: [] }) readonly colors!: Array<string>;
  @Prop() day!: Dayjs;
  @Prop() unit!: string;
  @Prop() selectedEntityId!: number;
  @Prop() selecedEntityCap!: number;
  @Prop() location!: string;
  @Prop() disabled!: boolean;

  public loading = true;
  public noData = false;
  public oldSize = window.innerWidth;
  private chart!: Chart;
  private dataSet!: UsageTimeseries[] | UsageTimeseries;
  private usageDataThisYear!: [string, number][];
  private usageDataLastYear!: [string, number][];
  public defaultView = this.isApp(this.PRODUCT_TYPE_FEED)
    ? 'weekly'
    : 'monthly';
  public view = this.defaultView;
  public timePeriod = {
    from: getStartOfMonth(this.day.subtract(8, 'month')).format(),
    to: getEndOfMonth(this.day.add(3, 'month')).format()
  };
  public disable = true;
  public value = null;

  @Prop({
    default: () => {
      return Chart.defaults.bar;
    }
  })
  readonly options: object | undefined;

  mounted() {
    this.defaultView = this.isApp(this.PRODUCT_TYPE_FEED)
      ? 'weekly'
      : 'monthly';
    this.changeView(this.defaultView, true);
  }

  @Watch('selectedEntityId')
  selectedUpdated() {
    this.changeView(this.defaultView);
    this.updateData(false);
  }

  get timePeriodFormatted() {
    return `${dayjs(this.timePeriod.from).format('ddd D MMM YY')} - ${dayjs(
      this.timePeriod.to
    ).format('ddd D MMM YY')}`;
  }

  public initChart() {
    this.createChart({
      labels: this.getLabels(),
      datasets: this.getData()
    });
    this.loading = false;
  }

  public update(timePeriod: { from: string; to: string }, view: string) {
    this.view = view;
    this.timePeriod = timePeriod;
    this.updateData(
      false,
      dayjs(timePeriod.from)
        .unix()
        .toString(),
      dayjs(timePeriod.to)
        .unix()
        .toString()
    );
  }

  public showUsageGraphModal() {
    GA.event<EventGraphModalOpen>(
      this.$gtag,
      new EventGraphModalOpen(
        `${this.getAppName(false)}_usage`,
        ProductStore.currentProduct?.productId,
        this.selectedEntityId,
        this.getEntityApiWord()
      )
    );

    this.$root.$emit('bv::show::modal', 'usageGraphModal', '#btnShow');
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `fuelBar-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'bar',
        data: chartData,
        options: {
          cornerRadius: this.location == 'modal' ? 15 : 5,
          legend: {
            display: !this.disabled,
            position: 'bottom',
            align: 'center',
            labels: {
              padding: 20,
              boxWidth: 13
            }
          },
          tooltips: {
            enabled: !this.disabled,
            callbacks: {
              label: (tooltipItems: any) => {
                return (
                  parseFloat(
                    roundFuelFeedValue(
                      formatFuelFeedValue(+tooltipItems.yLabel, this.unit),
                      this.unit
                    )
                  ).toLocaleString() + this.unit
                );
              }
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: !this.disabled
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: this.location == 'modal' ? true : false,
                  callback: (value: any) => {
                    return formatFuelFeedValue(
                      +value,
                      this.unit
                    ).toLocaleString();
                  }
                }
              }
            ]
          },
          plugins: {
            datalabels: {
              display: false
            }
          }
        } as ChartOptions
      };
      this.chart = new Chart(canvas, options);
    }
  }

  public convertUnits(data: any) {
    if (
      getObjectItem(`unitSettings-${this.getEntityApiWord()}`)?.usageUnit ==
      'm\u00B3'
    ) {
      return data.map((number: number) => number / 1000);
    } else {
      return data;
    }
  }

  public getData() {
    if (this.view == 'monthly') {
      return [
        {
          label: 'Previous Year',
          data: this.convertUnits(this.getDataArray(this.usageDataLastYear)),
          backgroundColor: this.colors[0]
        },
        {
          label: 'This Year',
          data: this.convertUnits(this.getDataArray(this.usageDataThisYear)),
          backgroundColor: this.colors[1]
        }
      ];
    } else {
      return [
        {
          label: `${dayjs(this.timePeriod.from).format('D MMM')} - ${dayjs(
            this.timePeriod.to
          ).format('D MMM')}`,
          data: this.convertUnits(this.getDataArray(this.usageDataThisYear)),
          backgroundColor: this.colors[1]
        }
      ];
    }
  }

  public getLabels() {
    const labels: string[] = [];
    this.usageDataThisYear.forEach(usage => {
      if (this.view == 'weekly') {
        labels.push(getStartOfWeek(this.day.week(+usage[0])).format('D MMM'));
      } else if (this.view == 'daily') {
        labels.push(usage[0].split('-')[0]);
      } else {
        labels.push(usage[0]); // the key string is stored at index 0
      }
    });
    return labels;
  }

  public getDataArray(data: [string, number][]) {
    const values: number[] = [];
    data.forEach(monthUsage => values.push(Math.round(monthUsage[1]))); // the monthly usage value is stored at index 1
    return values;
  }

  public startFromDate() {
    return dayjs(this.day).subtract(8, 'month');
  }

  public timePeriodLastYear() {
    return {
      start: dayjs(this.timePeriod.from)
        .subtract(1, 'year')
        .unix()
        .toString(),
      end: dayjs(this.timePeriod.to)
        .subtract(1, 'year')
        .unix()
        .toString()
    };
  }

  public changeView(newView: string, initialise = false) {
    trackEvent(`User changed ${this.getAppName(false)} usage chart view`, {
      view: newView
    });
    const prevDateRange = cloneDeep(this.timePeriod);
    this.view = newView;
    if (newView == 'monthly') {
      this.timePeriod.from = getStartOfMonth(
        this.day.subtract(8, 'month')
      ).format();
      this.timePeriod.to = getEndOfMonth(this.day.add(3, 'month')).format();
    } else if (newView == 'weekly') {
      this.timePeriod.from = getStartOfWeek(
        this.day.subtract(15 * 7, 'day')
      ).format(); //16 weeks previous inclusive
      this.timePeriod.to = this.day.format();
    } else if (newView == 'daily') {
      this.timePeriod.from = getStartOfDay(
        this.day.subtract(24, 'day')
      ).format(); //25 days previous
      this.timePeriod.to = this.day.format();
    }

    GA.event<EventGraphChangeDate>(
      this.$gtag,
      new EventGraphChangeDate(
        `${this.getAppName(false)}_usage`,
        ProductStore.currentProduct?.productId,
        this.selectedEntityId,
        this.getEntityApiWord(),
        prevDateRange,
        this.timePeriod
      )
    );

    this.updateData(initialise);
  }

  public updateData(
    initialise: boolean,
    from = dayjs(this.timePeriod.from)
      .unix()
      .toString(),
    to = dayjs(this.timePeriod.to)
      .unix()
      .toString()
  ) {
    this.loading = true;

    ProductStore.fetchUsageGraphData({
      appName: this.getAppName(false),
      entityId: this.selectedEntityId,
      from: +from,
      to: +to,
      disabled: this.disabled,
      source: `${this.getAppName(true)} Usage Graph - this year`,
      series: 'balance'
    }).then(withdrawalThis => {
      if (withdrawalThis.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
        if (this.view == 'monthly') {
          ProductStore.fetchUsageGraphData({
            appName: this.getAppName(false),
            entityId: this.selectedEntityId,
            from: +this.timePeriodLastYear().start,
            to: +this.timePeriodLastYear().end,
            disabled: this.disabled,
            source: `${this.getAppName(true)} Usage Graph - last year`,
            series: 'balance'
          }).then(withdrawalLast => {
            try {
              this.dataSet = [withdrawalLast, withdrawalThis];
              this.formatGraphData();
              if (initialise || !this.chart) {
                this.initChart();
              } else {
                this.chart.data.labels = this.getLabels();
                this.chart.data.datasets = this.getData();
                this.chart.update();
              }
            } catch (e) {
              console.error('Internal error', e);
              this.$bvToast.toast('Graph not updated' + e, {
                title: 'Error',
                toaster: 'b-toaster-bottom-center',
                solid: true,
                append: false
              });
            }
          });
        } else {
          let productsThis;
          if (this.isApp(this.PRODUCT_TYPE_FEED)) {
            this.dataSet = withdrawalThis as FeedBalance[];
            productsThis = this.dataSet.map((entry: FeedBalance) => {
              const day = dayjs(entry.date);
              let key = day.format('MMM');
              if (this.view == 'weekly') {
                key = getWeekOfYear(day).toString();
              } else if (this.view == 'daily') {
                key = day.format('D-MM');
              }
              const usage = this.unit == 't' ? entry.usageWeight : entry.usage;
              return {
                key: key,
                value: usage > 0 ? usage : 0,
                year: day.format('YYYY')
              };
            });
          } else if (this.isApp(this.PRODUCT_TYPE_WATER)) {
            this.dataSet = withdrawalThis as MeterTimeseriesReading[];
            productsThis = this.dataSet.map((entry: MeterTimeseriesReading) => {
              const day = dayjs.unix(entry.timestamp);
              let key = day.format('MMM');
              if (this.view == 'weekly') {
                key = getWeekOfYear(day).toString();
              } else if (this.view == 'daily') {
                key = day.format('D-MM');
              }
              return {
                key: key,
                value: entry.volume,
                year: day.format('YYYY')
              };
            });
          } else {
            this.dataSet = withdrawalThis as TankTimeseriesReading[];
            productsThis = this.dataSet.map((entry: TankTimeseriesReading) => {
              const day = dayjs.unix(entry.timestamp);
              let key = day.format('MMM');
              if (this.view == 'weekly') {
                key = getWeekOfYear(day).toString();
              } else if (this.view == 'daily') {
                key = day.format('D-MM');
              }
              return {
                key: key,
                value: entry.value,
                year: day.format('YYYY')
              };
            });
          }

          this.usageDataThisYear = Array.from(
            this.groupByMonth(productsThis, (value: any) => value.key)
          );
          if (initialise || !this.chart) {
            this.initChart();
          } else {
            this.chart.data.labels = this.getLabels();
            this.chart.data.datasets = this.getData();
            this.chart.update();
          }
        }
      }
      this.loading = false;
    });
  }

  public groupByMonth(list: any, keyGetter: any) {
    const map = this.getDatesMap();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, item.value);
      } else {
        map.set(key, item.value + +collection);
      }
    });
    return map;
  }

  public formatGraphData(): any {
    const lastEndTimestamp = this.timePeriodLastYear().end;
    if (Array.isArray(this.dataSet[0])) {
      let productsLast;
      if (this.isApp(this.PRODUCT_TYPE_FEED)) {
        const feedLast = this.dataSet[0] as FeedBalance[];
        productsLast = feedLast.map((entry: FeedBalance) => {
          const day = dayjs(entry.date);
          let key = day.format('MMM');
          if (this.view == 'weekly') {
            key = getWeekOfYear(day).toString();
          } else if (this.view == 'daily') {
            key = day.format('D-MM');
          }
          const usage = this.unit == 't' ? entry.usageWeight : entry.usage;

          return {
            key: key,
            value: day.unix() <= +lastEndTimestamp && usage > 0 ? usage : 0,
            year: day.format('YYYY')
          };
        });
      } else if (this.isApp(this.PRODUCT_TYPE_WATER)) {
        const waterLast = this.dataSet[0] as MeterTimeseriesReading[];
        productsLast = waterLast.map((entry: MeterTimeseriesReading) => {
          const day = dayjs.unix(entry.timestamp);
          let key = day.format('MMM');
          if (this.view == 'weekly') {
            key = getWeekOfYear(day).toString();
          } else if (this.view == 'daily') {
            key = day.format('D-MM');
          }
          return {
            key: key,
            value: entry.timestamp <= +lastEndTimestamp ? entry.volume : 0,
            year: day.format('YYYY')
          };
        });
      } else {
        const fuelLast = this.dataSet[0] as TankTimeseriesReading[];
        productsLast = fuelLast.map((entry: TankTimeseriesReading) => {
          const day = dayjs.unix(entry.timestamp);
          let key = day.format('MMM');
          if (this.view == 'weekly') {
            key = getWeekOfYear(day).toString();
          } else if (this.view == 'daily') {
            key = day.format('D-MM');
          }
          return {
            key: key,
            value: entry.timestamp <= +lastEndTimestamp ? entry.value : 0,
            year: day.format('YYYY')
          };
        });
      }

      this.usageDataLastYear = Array.from(
        this.groupByMonth(productsLast, (value: any) => value.key)
      );
    }
    if (Array.isArray(this.dataSet[1])) {
      let productsThis;
      if (this.isApp(this.PRODUCT_TYPE_FEED)) {
        const feedThis = this.dataSet[1] as FeedBalance[];
        productsThis = feedThis.map((entry: FeedBalance) => {
          const day = dayjs(entry.date);
          let key = day.format('MMM');
          if (this.view == 'weekly') {
            key = getWeekOfYear(day).toString();
          } else if (this.view == 'daily') {
            key = day.format('D-MM');
          }
          const usage = this.unit == 't' ? entry.usageWeight : entry.usage;

          return {
            key: key,
            value: usage > 0 ? usage : 0,
            year: day.format('YYYY')
          };
        });
      } else if (this.isApp(this.PRODUCT_TYPE_WATER)) {
        const waterLast = this.dataSet[1] as MeterTimeseriesReading[];
        productsThis = waterLast.map((entry: MeterTimeseriesReading) => {
          const day = dayjs.unix(entry.timestamp);
          let key = day.format('MMM');
          if (this.view == 'weekly') {
            key = getWeekOfYear(day).toString();
          } else if (this.view == 'daily') {
            key = day.format('D-MM');
          }
          return {
            key: key,
            value: entry.volume,
            year: day.format('YYYY')
          };
        });
      } else {
        const fuelThis = this.dataSet[1] as TankTimeseriesReading[];
        productsThis = fuelThis.map((entry: TankTimeseriesReading) => {
          const day = dayjs.unix(entry.timestamp);
          let key = day.format('MMM');
          if (this.view == 'weekly') {
            key = getWeekOfYear(day).toString();
          } else if (this.view == 'daily') {
            key = day.format('D-MM');
          }
          return {
            key: key,
            value: entry.value,
            year: day.format('YYYY')
          };
        });
      }
      this.usageDataThisYear = Array.from(
        this.groupByMonth(productsThis, (value: any) => value.key)
      );
    }
  }

  public getDatesMap() {
    const dates = new Map();
    const startFromDate = dayjs(this.timePeriod.from);
    if (this.view == 'weekly') {
      for (let i = 0; i < 16; i++) {
        dates.set(getWeekOfYear(startFromDate.add(7 * i, 'day')).toString(), 0);
      }
    } else if (this.view == 'daily') {
      for (let i = 0; i < 25; i++) {
        dates.set(startFromDate.add(i, 'day').format('D-MM'), 0);
      }
    } else {
      for (let i = 0; i < 12; i++) {
        dates.set(startFromDate.add(i, 'month').format('MMM'), 0);
      }
    }

    return dates;
  }

  public changeTimePeriod(direction: string) {
    this.loading = true;
    if (direction == 'left') {
      this.timePeriod.to = getEndOfDay(
        dayjs(this.timePeriod.from).subtract(1, 'day')
      ).format();
      if (this.view == 'weekly') {
        this.timePeriod.from = dayjs(this.timePeriod.from)
          .subtract(7 * 16, 'day')
          .format();
      } else if (this.view == 'daily') {
        this.timePeriod.from = dayjs(this.timePeriod.from)
          .subtract(25, 'day')
          .format();
      }
      this.updateData(false);
    } else if (
      dayjs(this.timePeriod.to).format('YYYYMMDD') !=
      this.day.format('YYYYMMDD')
    ) {
      this.timePeriod.from = getStartOfDay(
        dayjs(this.timePeriod.to).add(1, 'day')
      ).format();
      if (this.view == 'weekly') {
        this.timePeriod.to = dayjs(this.timePeriod.to)
          .add(7 * 16, 'day')
          .format();
      } else if (this.view == 'daily') {
        this.timePeriod.to = dayjs(this.timePeriod.to)
          .add(25, 'day')
          .format();
      }
      if (dayjs(this.timePeriod.to).isAfter(this.day)) {
        this.timePeriod.to = this.day.format();
      }
      this.updateData(false);
    } else {
      this.loading = false;
    }
  }
}
