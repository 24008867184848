
import FromToDatePicker from '@/components/buttons/FromToDatePicker.vue';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    FromToDatePicker
  }
})
export default class ActivityDatePickerModal extends Vue {
  @Prop() activitsyDate!: { from: string; to: string };
  @Prop() view!: string;
  public activityDate = {
    from: dayjs()
      .subtract(3, 'day')
      .format(),
    to: dayjs().format()
  };
  public max = dayjs().format('YYYY-MM-DD');

  mounted() {
    if (this.view == 'milk') {
      this.activityDate = {
        from: dayjs()
          .subtract(3, 'day')
          .format(),
        to: dayjs().format()
      };
    } else {
      this.activityDate = {
        from: dayjs()
          .subtract(7, 'day')
          .format(),
        to: dayjs().format()
      };
    }
  }

  get isFromBeforeTo() {
    return (
      this.activityDate.from == this.activityDate.to ||
      dayjs(this.activityDate.from).isBefore(dayjs(this.activityDate.to))
    );
  }

  public updateActivityList() {
    this.$root.$emit(
      'activityUpdated',
      dayjs(this.activityDate.from)
        .unix()
        .toString(),
      dayjs(this.activityDate.to)
        .unix()
        .toString()
    );
    this.$root.$emit(
      'customActivityDaysSelected',
      dayjs(this.activityDate.from),
      dayjs(this.activityDate.to)
    );
    this.closeActivityDatePickerModal();
  }

  public closeActivityDatePickerModal() {
    this.$root.$emit('bv::hide::modal', 'activityDatePickerModal', '#btnHide');
  }
}
