
import { Product } from '@/store/models/Product';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import VolumeScoreGraph from '../graphs/VolumeScoreGraph.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    VolumeScoreGraph
  },
  methods: {
    getToday,
    getDayjsFromUnix
  }
})
export default class VolumeScoreGraphModal extends mixins(AppName) {
  @Prop() currentEntity!: Product;
  @Prop() isNotLive!: boolean;

  private readonly placeholderTimetamp = 1664738629;

  public showVolumeScoreGraphModal() {
    this.$root.$emit('bv::show::modal', 'volScoreGraphModal', '#btnShow');
  }

  public closeVolumeScoreGraphModal() {
    this.$root.$emit('bv::hide::modal', 'volScoreGraphModal', '#btnHide');
  }
}
