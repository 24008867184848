
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import UsageChart from '@/components/graphs/UsageChart.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { Product } from '@/store/models/Product';
import { getObjectItem } from '@/utils/LocalStorageUtils';

@Component({
  components: {
    UsageChart
  },
  methods: {
    getToday,
    getDayjsFromUnix
  }
})
export default class UsageGraphModal extends mixins(AppName, ConstantsMixin) {
  @Prop() currentEntity!: Product;
  @Prop() title!: string;
  @Prop() isNotLive!: boolean;

  public savedUnits = getObjectItem(`unitSettings-${this.getEntityApiWord()}`);
  public waterUsageUnit = this.savedUnits?.usageUnit ?? 'm\u00B3';

  public closeUsageGraphModal() {
    this.$root.$emit('bv::hide::modal', 'usageGraphModal', '#btnHide');
  }
}
