
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import FuelCustomSettingsGroup from '../groups/FuelCustomSettingsGroup.vue';
import CustomFieldDataList from '../list/CustomFieldDataList.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import EntitySidebarHeader from './EntitySidebarHeader.vue';
import LowLevelTankSettings from './LowLevelTankSettings.vue';
import UsageDayPatternSettings from './UsageDayPatternSettings.vue';

@Component({
  components: {
    CustomFieldDataList,
    EntitySidebarHeader,
    FuelCustomSettingsGroup,
    SidebarCard,
    LowLevelTankSettings,
    UsageDayPatternSettings
  },
  methods: {}
})
export default class EntitySettingsSideContent extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() entityIndex!: number;

  @Prop({ required: true, type: Boolean })
  public canEdit!: boolean;

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }

  get currentEntity(): Product | null {
    return ProductStore.currentEntity ?? null;
  }

  public nextEntity() {
    const newEntityIndex =
      this.entityIndex == (this.currentProduct?.entities?.length ?? 1) - 1
        ? 0
        : this.entityIndex + 1;
    this.$root.$emit('setEntityIndex', newEntityIndex);
  }

  public prevEntity() {
    const newEntityIndex =
      this.entityIndex == 0
        ? (this.currentProduct?.entities?.length ?? 1) - 1
        : this.entityIndex - 1;

    this.$root.$emit('setEntityIndex', newEntityIndex);
  }

  public specificEntity(index: number) {
    this.$root.$emit('setEntityIndex', index);
  }
}
