
import { trackEvent } from '@/services/Mixpanel';
import { Product } from '@/store/models/Product';
import '@/utils/RoundedBar.ts';
import dayjs from 'dayjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateSwitch from '../common/DateSwitch.vue';
import DayPeriodButtons from '../common/DayPeriodButtons.vue';
import MilkScoreScale from '../items/MilkScoreScale.vue';
import MilkScoreGraph from './MilkScoreGraph.vue';
import MilkVolumeGraph from './MilkVolumeGraph.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSidebarMilkscoreGraphPreview } from '@/services/ga/events/EventSidebarMilkscoreGraphPreview';
import TimePeriod from '@/store/models/TimePeriod';
import { EventGraphChangeDate } from '@/services/ga/events/EventGraphChangeDate';

@Component({
  components: {
    MilkVolumeGraph,
    MilkScoreGraph,
    MilkScoreScale,
    DateSwitch,
    DayPeriodButtons
  }
})
export default class VolumeScoreGraph extends Vue {
  @Prop({ default: [] }) readonly colors!: Array<string>;
  @Prop() day!: string;
  @Prop() selectedVatId!: number;
  @Prop() selectedEntity!: Product;
  @Prop() location!: string;
  @Prop() disabled!: boolean;

  public loading = true;
  public noData = false;
  public oldSize = window.innerWidth;
  public modalPeriod = 2;
  public showGraphs = false;
  public timePeriod = {
    from: dayjs(this.day)
      .subtract(this.location == 'modal' ? this.modalPeriod : 1, 'day')
      .format(),
    to: dayjs(this.day).format()
  };

  @Watch('selectedVatId')
  selectedUpdated() {
    this.showGraphs = false;
  }

  public activateShowGraphs() {
    trackEvent('User viewed milk score and milk volume graphs');

    GA.event<EventSidebarMilkscoreGraphPreview>(
      this.$gtag,
      new EventSidebarMilkscoreGraphPreview(
        this.selectedEntity?.productId,
        this.timePeriod
      )
    );

    this.showGraphs = true;
  }

  public showvolScoreGraphModal() {
    this.$emit('openVolScoreModal');
  }

  public getDayDiff(start: dayjs.Dayjs, end: dayjs.Dayjs) {
    return end.diff(start, 'day');
  }

  public updateTimePeriod(timePeriod: TimePeriod) {
    GA.event<EventGraphChangeDate>(
      this.$gtag,
      new EventGraphChangeDate(
        'volume-milk-score',
        this.selectedEntity?.productId,
        this.selectedVatId,
        'vats',
        timePeriod
      )
    );
  }
}
