var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedEntityState && _vm.currentEntity)?_c('BSidebar',{ref:"detailSideBar",attrs:{"id":"sidebar-generic","right":"","no-header":"","bg-variant":"lightGrey","width":_vm.width > 1000 ? '400px' : `${_vm.width}px`},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(
        _vm.currentEntity &&
          !_vm.isApp(_vm.PRODUCT_TYPE_WATER) &&
          _vm.currentEntity.status == 'live'
      )?_c('div',{staticClass:"h-100"},[_c('BButton',{staticStyle:{"width":"calc(100% - 16px)","position":"relative","bottom":"66px","z-index":"200"},attrs:{"variant":_vm.getAppColour(true)},on:{"click":function($event){return _vm.updateRoute()}}},[_vm._v(_vm._s(_vm.isInRoute ? 'REMOVE FROM ' : 'ADD TO ')+"ROUTE")])],1):_vm._e()]},proxy:true}],null,false,1381869764),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('BContainer',{staticClass:"p-2",staticStyle:{"margin-bottom":"60px"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"text-center",staticStyle:{"margin-top":"20rem"},attrs:{"variant":_vm.getAppColour(true),"id":"spinner-sidebar"}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('SidebarHeader',{attrs:{"headerName":_vm.partnerDefinedName ||
            (_vm.selectedEntityState && _vm.selectedEntityState.siteName) ||
            '',"hideClose":_vm.hideClose,"headerId":_vm.partnerDefinedId},on:{"closeSidebar":_vm.closeSidebar}}),_c('BTabs',{staticClass:"position-relative",attrs:{"fill":"","active-nav-item-class":`bg-lightGrey border-${_vm.getAppColour(
            true
          )} border-top-0 border-left-0 border-right-0 `},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('BTab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.currentEntity)?_c('span',[(_vm.selectedEntityState.entities.length > 1 || false)?_c('span',{class:`position-absolute text-${_vm.getAppColour(true)}`,style:(`z-index: 210; transform: translate(5px, -10px); font-size:22px; opacity: 60%;`)},[_c('BIcon',{attrs:{"scale":"0.6","icon":"circle-fill"}})],1):_vm._e(),(_vm.selectedEntityState.entities.length > 1 || false)?_c('span',{staticClass:"text-white text-center position-absolute",style:(`z-index: 210; font-size:12px; max-width: 22px; transform: translate(15px, -3px)`)},[_vm._v(" "+_vm._s(_vm.selectedEntityState.entities.length)+" ")]):_vm._e(),_c('img',{attrs:{"src":_vm.getEntityImage(),"width":"23","height":"23","alt":"Tanks icon"}})]):_c('BIcon',{attrs:{"scale":"1","icon":"circle"}})]},proxy:true}],null,false,155654466)},[_c('EntitySideContent',{attrs:{"selectedEntityState":_vm.selectedEntityState,"selected":_vm.selected,"entityIndex":_vm.entityIndex,"width":_vm.width},on:{"setEntityIndex":i => _vm.$emit('setEntityIndex', i)}})],1),_c('BTab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('BIcon',{attrs:{"variant":"lightBlack","icon":"geo-alt"}})]},proxy:true}],null,false,1027666119)},[_c('SiteSideContent',{attrs:{"selectedEntityState":_vm.selectedEntityState,"entityIndex":_vm.entityIndex,"width":_vm.width},on:{"updatedLabels":_vm.updatedLabels}})],1),_c('BTab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('BIcon',{attrs:{"variant":"lightBlack","icon":"gear"}})]},proxy:true}],null,false,1220774127)},[_c('SettingsSideContent',{attrs:{"selected":_vm.selected,"entityIndex":_vm.entityIndex}})],1)],1)],1):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }