import { GAEvent } from '@/services/ga/events/GAEvent';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventSidebarActivityListChangeDateRange extends GAEvent {
  public eventName = 'sidebar-activity-list-change-date-range';
  public productId?: number;
  public entityId?: number;
  public entityType?: EntityApiType;
  public prevRange?: number;
  public nextRange?: number;

  constructor(
    productId?: number,
    entityId?: number,
    entityType?: EntityApiType,
    prevRange?: number,
    nextRange?: number
  ) {
    super();
    this.productId = productId;
    this.entityId = entityId;
    this.entityType = entityType;
    this.prevRange = prevRange;
    this.nextRange = nextRange;
  }
}
