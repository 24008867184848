
import ActivityListCard from '@/components/cards/ActivityListCard.vue';
import BurndownActivityGraphCard from '@/components/cards/BurndownActivityGraphCard.vue';
import UsageGraphCard from '@/components/cards/UsageGraphCard.vue';
import MetricTile from '@/components/common/MetricTile.vue';
import LowLevelTankSettings from '@/components/sidebar/LowLevelTankSettings.vue';
import { FeedMetrics, FuelMetricsTank, Product } from '@/store/models/Product';
import { PlaceholderData } from '@/utils/classes/PlaceholderData';
import { Constants } from '@/utils/Constants';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { toTitleCase } from '@/utils/FormattingUtils';
import {
  formatDaysTillEmpty,
  formatLastPickup,
  formatMilkScore,
  formatMilkVolume
} from '@/utils/TableFormatters';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import SidebarWarning from '../sidebar/SidebarWarning.vue';
import { getDate } from '@/utils/DateUtils';
import { productType } from '@/utils/types/ProductType';
import ConstantsMixin from '../mixin/Constants.vue';
import EntitySidebarHeader from './EntitySidebarHeader.vue';
import MetricTileGroup from '../groups/MetricTileGroup.vue';
import EntityGraphsGroup from '../groups/EntityGraphsGroup.vue';
import FaultImpactWarning from './FaultImpactWarning.vue';
import FuelCustomSettingsGroup from '../groups/FuelCustomSettingsGroup.vue';
import ProductStore from '@/store/modules/ProductStore';
import CustomFieldDataListCard from '../cards/CustomFieldDataListCard.vue';
import DetailsCard from '../cards/DetailsCard.vue';

@Component({
  components: {
    ActivityListCard,
    CustomFieldDataListCard,
    LowLevelTankSettings,
    MetricTile,
    SidebarWarning,
    UsageGraphCard,
    BurndownActivityGraphCard,
    EntitySidebarHeader,
    MetricTileGroup,
    EntityGraphsGroup,
    FaultImpactWarning,
    FuelCustomSettingsGroup,
    DetailsCard
  },
  methods: {
    formatLastPickup,
    formatMilkScore,
    formatMilkVolume,
    toTitleCase,
    getToday,
    getDayjsFromUnix,
    formatDaysTillEmpty,
    getDate
  }
})
export default class EntitySideContent extends mixins(AppName, ConstantsMixin) {
  @Prop() selected!: boolean;
  @Prop() entityIndex!: number;
  @Prop() width!: number;
  public orgId = 0;
  public activityDays: number | null = 7;
  public customActivityDates = '';

  get currentEntity(): Product | null {
    return ProductStore.currentEntity ?? null;
  }

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }

  get currentEntityIndex(): number {
    return ProductStore.currentEntityIndex ?? 0;
  }

  get entityTitles(): {
    title: string;
    subtitle: string;
    secondSubtitle: string;
  } {
    return this.getEntityHeaderInfo(this.currentEntity);
  }

  get isNotLive() {
    return this.currentEntity?.status && this.currentEntity?.status != 'live';
  }

  getPlaceholderEntity() {
    return new PlaceholderData(
      this.getAppName(false) as productType,
      this.currentEntity
    ).product;
  }

  public nextEntity() {
    const newEntityIndex =
      this.entityIndex == (this.currentProduct?.entities?.length ?? 1) - 1
        ? 0
        : this.entityIndex + 1;
    this.$emit('setEntityIndex', newEntityIndex);
  }

  public prevEntity() {
    const newEntityIndex =
      this.entityIndex == 0
        ? (this.currentProduct?.entities?.length ?? 1) - 1
        : this.entityIndex - 1;

    this.$emit('setEntityIndex', newEntityIndex);
  }

  public specificEntity(index: number) {
    this.$emit('setEntityIndex', index);
  }

  public getDay(date: string) {
    return dayjs(date).format('D MMM');
  }
}
