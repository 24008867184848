
import FormTextInput from '@/components/common/FormTextInput.vue';
import Toast from '@/components/common/Toast.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import { UpdateCustomSettingsData } from '@/services/api/CustomSettingApi';
import { trackEvent } from '@/services/Mixpanel';
import { storeState } from '@/store/Globals';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import { FeedMetrics, FuelMetricsTank } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { getFeedAmount, isWeight } from '@/utils/FeedUtils';
import { customRounding, toTitleCase } from '@/utils/FormattingUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    FormTextInput,
    Toast,
    ConfirmationModal
  },
  methods: {
    customRounding,
    isAdmin,
    toTitleCase
  }
})
export default class LowLevelTankSettings extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() disabled!: boolean;

  public showPercent = true;
  public updated = false;
  public globalDefaultLow = 92;
  public globalDefaultCritical = 92;
  public previousValues: {
    entityId: number;
    lowLevel: number;
    criticalLevel: number;
  } | null = null;
  public error = '';
  public criticalPercentVal = 11;
  public lowPercentVal = 31;
  public oldValues = { low: 31, critical: 31 };
  public isDefault = { low: true, critical: true };
  public sliderWidth = 200;

  get currentEntity() {
    return ProductStore.currentEntity ?? undefined;
  }

  get storeLoadingState() {
    return storeState.loading;
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.updateDefaults();
    }
  }

  mounted() {
    if (this.currentEntity) {
      this.updateValues();
    }
    //set global default values to what is stored at partner level
    if (!this.storeLoadingState) {
      this.updateDefaults();
    }
    this.setRangeWidth();
    window.addEventListener('resize', this.setRangeWidth);
    window.addEventListener('mouseup', this.setRangeWidth);
  }
  destroyed() {
    window.removeEventListener('resize', this.setRangeWidth);
    window.removeEventListener('mouseup', this.setRangeWidth);
  }

  public setRangeWidth() {
    setTimeout(() => {
      this.sliderWidth =
        (this.$refs.critBox as HTMLElement)?.clientWidth ?? 200;
    }, 500);
  }

  public updateDefaults() {
    const fuelCustomSettings = CustomStore.customEntitySettings as FuelCustomSettingsData;
    this.globalDefaultLow = fuelCustomSettings?.lowLevelThreshold ?? 30;
    this.globalDefaultCritical =
      fuelCustomSettings?.criticalLevelThreshold ?? 10;
    if (this.isDefault.low) {
      this.lowPercentVal = fuelCustomSettings?.lowLevelThreshold ?? 30;
      this.oldValues.low = fuelCustomSettings?.lowLevelThreshold ?? 30;
    }
    if (this.isDefault.critical) {
      this.criticalPercentVal =
        fuelCustomSettings?.criticalLevelThreshold ?? 10;
      this.oldValues.critical =
        fuelCustomSettings?.criticalLevelThreshold ?? 10;
    }
  }

  @Watch('currentEntity', { deep: true })
  changeInProduct() {
    this.updated = false;
    this.isDefault.low = false;
    this.isDefault.critical = false;
    this.updateValues();
  }

  @Watch('showPercent')
  changeShowPercent() {
    this.updateValues();
  }

  get isDefaultLow() {
    return this.globalDefaultLow == this.lowPercentVal;
  }

  get isDefaultCritical() {
    return this.globalDefaultCritical == this.criticalPercentVal;
  }

  get lowValue() {
    return this.getValue(this.lowPercentVal);
  }

  get criticalValue() {
    return this.getValue(this.criticalPercentVal);
  }

  public getValue(percentValue: number) {
    if (this.showPercent) {
      return Math.round(percentValue * 5) / 5;
    } else {
      return customRounding(
        ((this.currentEntity?.metrics as FuelMetricsTank | FeedMetrics)
          ?.nominalVolume ?? 0) *
          (percentValue / 100)
      );
    }
  }

  public formatValue(value: number) {
    if (this.showPercent) {
      return value;
    } else {
      return this.isApp(this.PRODUCT_TYPE_FEED) && isWeight(this.currentEntity)
        ? getFeedAmount(value, this.currentEntity, true)
        : value.toLocaleString();
    }
  }

  public updateValues() {
    const fuelCustomSettingsData = this.currentEntity
      ?.entityCustomSettingsData as FuelCustomSettingsData;
    if (
      (this.currentEntity && fuelCustomSettingsData?.criticalLevelThreshold) ||
      (fuelCustomSettingsData?.lowLevelThreshold && !this.updated)
    ) {
      if (fuelCustomSettingsData?.lowLevelThreshold) {
        this.isDefault.low = false;
        this.lowPercentVal = fuelCustomSettingsData?.lowLevelThreshold;
        this.oldValues.low = fuelCustomSettingsData?.lowLevelThreshold;
      } else {
        this.isDefault.low = true;
      }
      if (fuelCustomSettingsData?.criticalLevelThreshold) {
        this.isDefault.critical = false;
        this.criticalPercentVal =
          fuelCustomSettingsData?.criticalLevelThreshold;
        this.oldValues.critical =
          fuelCustomSettingsData?.criticalLevelThreshold;
      } else {
        this.isDefault.critical = true;
      }
    } else {
      this.isDefault.low = true;
      this.isDefault.critical = true;
    }
    if (this.isDefault.low || this.isDefault.critical) {
      this.updateDefaults();
    }
  }

  public newLowInput(value: any) {
    if (value.newValue != this.oldValues.low) {
      if (value.newValue < this.criticalPercentVal) {
        this.error = 'Critical level value must be lower than low level value';
      } else {
        this.error = '';
      }
      this.updated = true;
    }
  }
  public newCriticalInput(value: any) {
    if (value.newValue != this.oldValues.critical) {
      if (value.newValue > this.lowPercentVal) {
        this.criticalPercentVal = this.lowPercentVal;

        this.error = 'Critical level value must be lower than low level value';
      } else {
        this.error = '';
      }
      this.updated = true;
    }
  }

  public submitUpdateData() {
    if (this.currentEntity) {
      const fuelCustomSettingsData = this.currentEntity
        .entityCustomSettingsData as FuelCustomSettingsData;
      this.previousValues = {
        entityId: this.currentEntity.entityId,
        lowLevel:
          fuelCustomSettingsData?.lowLevelThreshold ?? this.globalDefaultLow,
        criticalLevel:
          fuelCustomSettingsData?.criticalLevelThreshold ??
          this.globalDefaultCritical
      };
      if (this.lowPercentVal > 75) {
        this.lowPercentVal = 75;
        this.error =
          'The low level value was over 75% of the tank, which is over the max low level value, therefore has been automatically set to 75% of the tank.';
      } else if (this.lowPercentVal < 5) {
        this.lowPercentVal = 5;
        this.error =
          'The low level value was under 5% of the tank, which is under the min low level value, therefore has been automatically set to 5% of the tank.';
      } else {
        this.error = '';
      }
      if (this.criticalPercentVal > 75) {
        this.criticalPercentVal = 75;
        if (this.error != '') {
          this.error =
            this.error +
            'The critical level value was over 75% of the tank, which is over the max critical level value, therefore has been automatically set to 75% of the tank.';
        } else {
          this.error =
            'The critical level value was over 75% of the tank, which is over the max critical level value, therefore has been automatically set to 75% of the tank.';
        }
      } else if (this.criticalPercentVal < 5) {
        this.criticalPercentVal = 5;
        if (this.error != '') {
          this.error =
            this.error +
            'The critical level value was under 5% of the tank, which is under the min critical level value, therefore has been automatically set to 5% of the tank.';
        } else {
          this.error =
            'The critical level value was under 5% of the tank, which is under the min critical level value, therefore has been automatically set to 5% of the tank.';
        }
      } else {
        this.error = '';
      }
      UpdateCustomSettingsData({
        partnerId: User.orgId,
        entityType: this.getEntityApiWord(),
        entityId: +(this.currentEntity.entityId ?? 0),
        data: {
          ...(CustomStore.customEntitySettings as FuelCustomSettingsData),
          lowLevelThreshold: this.lowPercentVal,
          criticalLevelThreshold: this.criticalPercentVal
        }
      }).then(() => {
        this.updated = false;
        this.$root.$emit('customFieldsUpdated');
      });

      trackEvent(`User changed ${this.getAppName(false)} low level settings`, {
        entityId: (this.currentEntity.entityId ?? 0).toString(),
        lowLevelThreshold: this.lowPercentVal.toString(),
        criticalLevelThreshold: this.criticalPercentVal.toString()
      });
    }
    this.$bvToast.show('changesApplied');
  }

  public undoChanges() {
    this.lowPercentVal = this.previousValues?.lowLevel ?? this.lowPercentVal;
    this.criticalPercentVal =
      this.previousValues?.criticalLevel ?? this.criticalPercentVal;

    this.$bvToast.hide('changesApplied');
    this.$bvToast.show('changesUndone');
  }

  public resetDefault() {
    this.lowPercentVal = this.globalDefaultLow;
    this.criticalPercentVal = this.globalDefaultCritical;
    if (this.currentEntity) {
      UpdateCustomSettingsData({
        partnerId: User.orgId,
        entityType: this.getEntityApiWord(),
        entityId: +(this.currentEntity.entityId ?? 0),
        data: {
          ...(CustomStore.customEntitySettings as FuelCustomSettingsData),
          lowLevelThreshold: null,
          criticalLevelThreshold: null
        }
      }).then(() => {
        this.updated = false;
        this.$root.$emit('customFieldsUpdated');
      });
    }
    this.$bvToast.show('changesApplied');
  }
}
