
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import CustomFieldDataList from '../list/CustomFieldDataList.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    CustomFieldDataList
  }
})
export default class CustomFieldDataListCard extends mixins(AppName) {
  @Prop() siteView!: boolean;

  get currentProduct(): Product | null {
    return ProductStore.currentProduct ?? null;
  }
}
