var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentProduct)?_c('div',[_c('BForm',{staticClass:"p-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitUpdateData.apply(null, arguments)}}},[_c('FormTextInput',{attrs:{"inputAttrs":{
        id: `${_vm.siteView ? 'site' : 'tank'}CustName`,
        label: `${_vm.siteView ? '' : 'Tank '}Name`,
        labelClass: 'text-primary d-flex',
        type: 'text',
        variant: _vm.appColour,
        value: _vm.partnerDefinedName,
        disabled: _vm.disabled
      },"id":`${_vm.siteView ? 'site' : 'tank'}CustFieldDef${_vm.tankIndex}`},on:{"input":_vm.newInput},model:{value:(_vm.partnerDefinedName),callback:function ($$v) {_vm.partnerDefinedName=$$v},expression:"partnerDefinedName"}}),_c('FormTextInput',{attrs:{"inputAttrs":{
        id: `${_vm.siteView ? 'site' : 'tank'}CustId`,
        label: `${_vm.siteView ? '' : 'Tank '}Id`,
        labelClass: 'text-primary d-flex',
        type: 'text',
        variant: _vm.appColour,
        value: _vm.partnerDefinedId,
        disabled: _vm.disabled
      },"id":`${_vm.siteView ? 'site' : 'tank'}CustFieldDef${_vm.tankIndex}`},on:{"input":_vm.newInput},model:{value:(_vm.partnerDefinedId),callback:function ($$v) {_vm.partnerDefinedId=$$v},expression:"partnerDefinedId"}}),(_vm.updated)?_c('BButton',{attrs:{"type":"submit","variant":_vm.appColour}},[_vm._v("Save Changes")]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }