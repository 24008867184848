import { VatTimeseriesReading } from '@/services/api/models/VatTimeseries';
import { MilkMetrics, Product } from '@/store/models/Product';
import TimePeriod from '@/store/models/TimePeriod';
import dayjs from 'dayjs';
import { sortByTimestamp } from './SortingUtils';

export const milkingTimeBackground = {
  id: 'milkingTimeBackground',
  beforeDatasetsDraw(chart: any) {
    const {
      ctx,
      data: { datasets },
      chartArea: { top },
      scales
    } = chart;
    const x = scales['x-axis-0'];
    const y = scales['y-axis-0'];

    ctx.fillStyle = 'rgba(200,200,200,0.15)';
    ctx.strokeStyle = 'red';
    let lastPoint: Date | null = null;
    const milkingDataset = datasets.find(
      (dataset: any) => dataset.label == 'Milking'
    );
    if (milkingDataset) {
      milkingDataset.data.forEach((datapoint: any) => {
        if (!isNaN(datapoint.y)) {
          if (lastPoint != null) {
            // Assumed to be the milking finish point so draw a rect from milking start to finish
            ctx.fillRect(
              x.getPixelForValue(lastPoint),
              top,
              x.getPixelForValue(datapoint.x) - x.getPixelForValue(lastPoint),
              y.height
            );
            lastPoint = null;
          } else {
            // Assumed to be a milking start point
            lastPoint = datapoint.x;
          }
        } else {
          lastPoint = null;
        }
      });
    }

    ctx.restore();
  }
};

export function formatMilkingHoldingData(
  timePeriod: TimePeriod,
  day: string,
  location: string,
  modalPeriod: number,
  graphData: VatTimeseriesReading[],
  volumeData: { timestamp: number; value: number }[],
  selectedEntity: Product
): any {
  const milkingData: {
    value: number;
    timestamp: number;
    label: string;
  }[] = [];
  const holdingData: {
    value: number;
    timestamp: number;
    label: string;
  }[] = [];
  const labels: number[] = [];

  let hour = Math.ceil(dayjs(timePeriod.from).hour() / 6) * 6;
  for (let j = 0; j < (location == 'sidebar' ? 4 : modalPeriod * 4); j++) {
    labels.push(
      dayjs(timePeriod.from)
        .hour(hour)
        .minute(0)
        .second(0)
        .valueOf()
    );
    hour = hour + 6;
  }

  if (graphData.length != 0) {
    const lastEvent = graphData[graphData.length - 1];
    if (graphData[0].label == 'milkingStart' && graphData[0].type == 'first') {
      graphData.push({
        ...volumeData[0],
        value: 0,
        label: 'pickupFinish'
      });
    } else {
      graphData.push({ ...volumeData[0], label: 'pickupFinish' });
    }

    if (lastEvent.label == 'pickupFinish' && lastEvent.type == 'full') {
      graphData.push({
        ...volumeData[volumeData.length - 1],
        value: 0,
        label: 'milkingStart'
      });
    } else {
      if (
        dayjs(timePeriod.to).format('DD-MM-YYYY') ==
        dayjs(day).format('DD-MM-YYYY')
      ) {
        graphData.push({
          timestamp: dayjs(day).valueOf(),
          value: selectedEntity
            ? (selectedEntity.metrics as MilkMetrics).vatVolume
            : volumeData[volumeData.length - 1].value,
          label: 'last'
        });
      } else {
        graphData.push({
          ...volumeData[volumeData.length - 1],
          label: 'last'
        });
      }
    }

    graphData = sortByTimestamp(graphData);

    graphData = graphData.map(value => {
      if (value.label == 'pickupFinish' && value.type == 'full') {
        return { ...value, value: 0 };
      }
      if (value.label == 'milkingStart' && value.type?.includes('first')) {
        return { ...value, value: 0 };
      }
      return value;
    });

    let milkingInProgress = false;
    graphData.forEach(val => {
      if (val.label == 'milkingStart') {
        milkingInProgress = true;
        // labels.push(val.timestamp);
      }

      if (
        (val.label == 'pickupFinish' || val.label == 'pickupStart') &&
        !milkingInProgress
      ) {
        milkingData.push({ ...val, value: NaN });
        holdingData.push(val);
      } else if (val.label == 'milkingFinish') {
        milkingInProgress = false;
        // labels.push(val.timestamp);
        holdingData.push(val);
        milkingData.push(val);
        milkingData.push({
          value: NaN,
          timestamp: val.timestamp + 1,
          label: 'nan'
        });
      } else {
        holdingData.push(val);
        milkingData.push(val);
      }
    });
  }

  return {
    milkingData: milkingData.map((entry: any) => {
      return { x: new Date(entry.timestamp), y: Math.round(+entry.value) };
    }),
    holdingData: holdingData.map((entry: any) => {
      return { x: new Date(entry.timestamp), y: Math.round(+entry.value) };
    }),
    labels: labels
  };
}

export function formatFuelFeedValue(value: number, unit: string) {
  if (unit == 't') {
    //Converting from kilograms to tonnes
    return value / 1000;
  } else {
    return value;
  }
}

export function roundFuelFeedValue(value: number, unit: string) {
  if (unit == 't') {
    return value.toFixed(1);
  } else {
    return Math.round(value).toString();
  }
}
