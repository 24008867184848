
import { Product } from '@/store/models/Product';
import TimePeriod from '@/store/models/TimePeriod';
import ProductStore from '@/store/modules/ProductStore';
import '@/utils/RoundedBar.ts';
import dayjs from 'dayjs';
import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';

@Component
export default class DateSwitch extends Vue {
  @Model('updateTimePeriod')
  readonly timePeriod!: TimePeriod;
  @Prop() day!: string;
  @Prop() location!: string;
  @Prop() disabled!: boolean;
  @Prop() modalPeriod!: number;

  public disable = true;
  public loading = true;

  mounted() {
    this.resetTimePeriod();
  }

  get currentEntity(): Product | null {
    return ProductStore.currentEntity ?? null;
  }

  @Emit('updateTimePeriod')
  updateTimePeriod(newTimePeriod: TimePeriod) {
    return newTimePeriod;
  }

  @Watch('currentEntity')
  selectedUpdated() {
    this.resetTimePeriod();
  }

  @Watch('modalPeriod')
  modalPeriodUpdated() {
    this.resetTimePeriod();
  }

  public formatTimePeriod(): string {
    return (
      dayjs(this.timePeriod.from).format('DD MMM - ') +
      dayjs(this.timePeriod.to).format('DD MMM')
    );
  }

  public resetTimePeriod() {
    this.updateTimePeriod({
      from: dayjs(this.day)
        .subtract(this.location == 'modal' ? this.modalPeriod : 1, 'day')
        .format(),
      to: dayjs(this.day).format()
    });
    this.disable = true;
  }

  public changeTimePeriod(direction: string) {
    let newTimePeriod: TimePeriod;
    this.loading = true;
    if (direction == 'left') {
      newTimePeriod = {
        from: dayjs(this.timePeriod.from)
          .subtract(this.location == 'modal' ? this.modalPeriod : 1, 'day')
          .format(),
        to: this.timePeriod.from
      };
    } else {
      newTimePeriod = {
        from: dayjs(this.timePeriod.to).format(),
        to: dayjs(this.timePeriod.to)
          .add(this.location == 'modal' ? this.modalPeriod : 1, 'day')
          .format()
      };
    }
    this.disable =
      dayjs(newTimePeriod.to).format('YYYYMMDD') ==
      dayjs(this.day).format('YYYYMMDD');
    this.updateTimePeriod(newTimePeriod);
  }

  public getDayDiff(start: dayjs.Dayjs, end: dayjs.Dayjs) {
    return end.diff(start, 'day');
  }
}
