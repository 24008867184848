
import { Product } from '@/store/models/Product';
import { PlaceholderData } from '@/utils/classes/PlaceholderData';
import { Constants } from '@/utils/Constants';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import BurndownActivityGraph from '../graphs/BurndownActivityGraph.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    BurndownActivityGraph
  },
  methods: {
    getToday,
    getDayjsFromUnix
  }
})
export default class BurndownActivityGraphModal extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() currentEntity!: Product;
  @Prop() title!: string;
  @Prop() isNotLive!: boolean;

  get modalId() {
    return this.getAppName(false) == Constants.PRODUCT_TYPE_FEED
      ? 'feedLevelGraphModal'
      : 'fuelBurndownGraphModal';
  }

  public showBurndownActivityGraphModal() {
    this.$root.$emit('bv::show::modal', this.modalId, '#btnShow');
  }

  public closeBurndownActivityGraphModal() {
    this.$root.$emit('bv::hide::modal', this.modalId, '#btnHide');
  }

  getPlaceholderEntity() {
    return new PlaceholderData(
      this.getAppName(false) as productType,
      this.currentEntity
    ).product;
  }
}
