
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class SidebarWarning extends mixins(AppName) {
  @Prop() icon!: string;
  @Prop() text!: string;
  @Prop({ default: 'secondary' }) colour!: string;
}
