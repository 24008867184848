
import { ComplianceSettings } from '@/store/models/MilkScore';
import MilkScore from '@/store/models/MilkScore';
import { MilkMetrics } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { getComplianceScore } from '@/utils/MilkScoreUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class MilkScoreTooltip extends mixins(AppName) {
  @Prop({ required: true, type: Boolean }) isFonterra!: boolean;
  @Prop({ required: true, type: Number }) scaleWidth!: number;
  @Prop({ required: true, type: String }) colour!: string;
  @Prop({ required: true, type: String }) currentLevel!: string;
  @Prop({ required: true, type: Array }) thresholdRange!: number[];
  public test = 0;
  public offset = 0;
  public show = true;

  mounted() {
    this.updateScorePosition();
    this.$root.$on('tabChanged', (tab: number) => {
      if (tab == 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    });
  }

  get getToolTip() {
    if (this.isFonterra) return '';
    return this.currentAreaOutOfSpec != null
      ? Math.ceil(this.currentAreaOutOfSpec)
      : '-';
  }

  get currentEntity(): any | null {
    return ProductStore.currentEntity ?? null;
  }

  get currentScore() {
    if (this.currentEntity?.supplier == 'F') {
      return (this.currentEntity?.metrics as MilkMetrics).milkScore;
    }
    return getComplianceScore(
      (this.currentEntity?.metrics as MilkMetrics).milkScores,
      this.currentEntity?.entityCustomSettingsData as ComplianceSettings
    );
  }

  get currentAreaOutOfSpec() {
    if (this.currentEntity?.supplier != 'F') {
      return (this.currentScore as MilkScore).areaOutOfSpec ?? 0;
    }
    return null;
  }

  public updateScorePosition() {
    if (this.thresholdRange[1] != 0) {
      const pointOnRange = this.isFonterra
        ? this.currentEntity?.metrics?.milkScore
        : this.currentAreaOutOfSpec;
      if (
        pointOnRange > this.thresholdRange[1] ||
        (this.isFonterra && pointOnRange == 4)
      ) {
        this.offset = this.scaleWidth / 2;
      } else {
        //Finding the point in the threshold block, e.g. a value of 0.38 means the point is 38% of the way along the block
        const decimalAcrossInBlock =
          ((pointOnRange ?? 0) - this.thresholdRange[0]) /
          (this.thresholdRange[1] - this.thresholdRange[0]);
        //Calculating the number of pixels across, e.g. width of block is 150px, and it's 38% of the way, this value would be 57 as it is 57px from the start of the block
        const pixelsAcrossInBlock = decimalAcrossInBlock * this.scaleWidth;
        //This number is the offset from the center, number will be negative if it is before the halfway point and positive if after
        this.offset = pixelsAcrossInBlock - this.scaleWidth / 2;
      }
    } else {
      //Position at the very start of the block
      this.offset = -(this.scaleWidth / 2);
    }
  }

  @Watch('currentEntity')
  @Watch('thresholdRange')
  entityUpdated() {
    this.updateScorePosition();
  }
}
