
import VolumePercentGraph from '@/components/graphs/VolumePercentGraph.vue';
import VolumePercentGraphModal from '@/components/modal/VolumePercentGraphModal.vue';
import { Product } from '@/store/models/Product';
import { PlaceholderData } from '@/utils/classes/PlaceholderData';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    VolumePercentGraph,
    VolumePercentGraphModal,
    SidebarCard
  },
  methods: { getDayjsFromUnix, getToday }
})
export default class VolumePercentGraphCard extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop({ required: true, type: Object }) currentEntity!: Product;
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: Boolean }) isNotLive!: boolean;

  getPlaceholderEntity() {
    return new PlaceholderData(
      this.getAppName(false) as productType,
      this.currentEntity
    ).product;
  }
}
