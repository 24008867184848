
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import {
  TileElements,
  TileClassAlterations
} from '../../utils/types/MetricTileTypes';

@Component
export default class MetricTile extends mixins(AppName) {
  @Prop({ required: true, type: Object }) tileElements!: TileElements;
  @Prop({
    type: Object,
    default: () => {
      return {
        card: '',
        cardText: '',
        title: '',
        value: '',
        secondaryValue: '',
        subtitleValue: '',
        borderVariant: ''
      };
    }
  })
  classAlterations!: TileClassAlterations;
  @Prop({ required: true, type: Number }) width!: number;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  public getImage(icon: string) {
    const iconPrefix = this.disabled ? 'disabled' : this.getAppName(false);
    return require(`../../assets/${iconPrefix}-${icon}.svg`);
  }
}
