
import { Product } from '@/store/models/Product';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SidebarCard from '../common/SidebarCard.vue';
import MilkingTimeBar from '../graphs/MilkingTimeBar.vue';
import AppName from '../mixin/AppName.vue';
import MilkingTimeBarModal from '../modal/MilkingTimeBarModal.vue';

@Component({
  components: { MilkingTimeBar, MilkingTimeBarModal, SidebarCard },
  methods: { getDayjsFromUnix, getToday }
})
export default class MilkingTimeBarCard extends mixins(AppName) {
  @Prop() currentProduct!: Product;
  @Prop() isNotLive!: boolean;

  public showTimeGraphModal() {
    this.$root.$emit('bv::show::modal', 'timeGraphModal', '#btnShow');
  }
}
