
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import CombinedVolumeGraphCard from '../cards/CombinedVolumeGraphCard.vue';
import MilkActivityListCard from '../cards/MilkActivityListCard.vue';
import MilkingTimeBarCard from '../cards/MilkingTimeBarCard.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    MilkingTimeBarCard,
    CombinedVolumeGraphCard,
    MilkActivityListCard
  }
})
export default class SiteGraphsGroup extends mixins(AppName, ConstantsMixin) {
  @Prop() currentProduct!: Product;
  @Prop() numPendingEntities!: number;

  get isNotLive() {
    return this.numPendingEntities == this.currentProduct?.entities?.length;
  }

  get tooltipText() {
    if (this.numPendingEntities != this.currentProduct?.entities?.length) {
      return `${this.numPendingEntities} ${this.getEntityName(false)}${
        this.numPendingEntities == 1
          ? ' on this site has'
          : 's on this site have'
      } hardware installation pending, showing data from live ${this.getEntityName(
        false
      )}s`;
    } else {
      return 'Example graph (no data available to display)';
    }
  }
}
