
import { VatTimeseriesReading } from '@/services/api/models/VatTimeseries';
import { FetchVatGraphData } from '@/services/api/ProductApi';
import { MilkMetrics, Product } from '@/store/models/Product';
import {
  formatMilkingHoldingData,
  milkingTimeBackground
} from '@/utils/GraphUtils';
import '@/utils/RoundedBar.ts';
import { sortByTimestamp } from '@/utils/SortingUtils';
import Chart, {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartTooltipItem
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MilkVolumeGraph extends Vue {
  @Prop() day!: string;
  @Prop() selectedVatId!: number;
  @Prop() selectedEntity!: Product;
  @Prop() location!: string;
  @Prop() disabled!: boolean;
  @Prop() timePeriod!: { from: string; to: string };
  @Prop() modalPeriod!: number;

  public loading = true;
  public noData = false;
  private chart!: Chart;
  private graphData!: VatTimeseriesReading[];
  public volumeData: { timestamp: number; value: number }[] = [];
  public colours = ['#999999', '#CCCCCC'];

  get options() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 5,
          right: 20
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          padding: 20,
          boxWidth: 12
        }
      },
      tooltips: {
        enabled: !this.disabled,
        callbacks: {
          label: (tooltipItems: any) => {
            return tooltipItems.yLabel + ' L';
          }
        },
        filter: function(item: ChartTooltipItem, data: ChartData) {
          if (item.datasetIndex == 1) {
            return true;
          }
          return false;
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: !this.disabled,
              autoSkip: true,
              maxTicksLimit: 5,
              source: 'data'
            },
            afterFit: function(scaleInstance) {
              scaleInstance.width = 50;
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              display: !this.disabled,
              autoSkip: true,
              maxTicksLimit: this.location == 'sidebar' ? 4 : 12,
              source: 'labels',
              callback(label: any, index: number, labels: any) {
                if (!Array.isArray(label)) {
                  return [
                    label.toString().split(' ')[0],
                    label.toString().split(' ')[1] +
                      ' ' +
                      label.toString().split(' ')[2]
                  ];
                }

                return `${label}`;
              }
            },
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'h:mma D MMM'
              }
            }
          }
        ]
      },
      elements: {
        point: {
          pointRadius: 0
        },
        line: {
          tension: 0
        }
      },
      plugins: {
        datalabels: {
          color: '#F6861F',
          backgroundColor: '#e9ecef',
          borderRadius: 4,
          align: 'end',
          offset: 10,
          padding: 5,
          opactiy: 0.5
        }
      }
    } as ChartOptions;
  }

  mounted() {
    if (this.selectedVatId) {
      this.updateData();
    }
  }

  @Watch('timePeriod')
  @Watch('selectedVatId')
  triggerUpdate() {
    this.updateData();
  }

  public showvolScoreGraphModal() {
    this.$emit('openVolScoreModal');
  }

  public initChart() {
    Chart.pluginService.register(ChartDataLabels);
    if (this.graphData) {
      const data = this.getData();
      this.createChart(data);
      this.setChartYAxisMax();
      this.chart.update();
    } else {
      this.loading = true;
    }
  }

  public createChart(chartData: object) {
    const canvas: HTMLCanvasElement = this.$refs[
      `line-${this.selectedVatId}-${this.location}`
    ] as HTMLCanvasElement;
    if (canvas) {
      const options: ChartConfiguration = {
        type: 'line',
        data: chartData,
        options: this.options,
        plugins: [milkingTimeBackground]
      };
      this.chart = new Chart(canvas as HTMLCanvasElement, options);
    }
  }

  public updateData() {
    this.loading = true;
    FetchVatGraphData(
      this.selectedVatId.toString(),
      dayjs(this.timePeriod.from)
        .unix()
        .toString(),
      dayjs(this.timePeriod.to)
        .unix()
        .toString(),
      this.disabled,
      'Milk Volume Graph'
    ).then(data => {
      if (data.volumeEvents.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }

      try {
        this.volumeData = sortByTimestamp(data.volume);
        this.graphData = sortByTimestamp(data.volumeEvents);
        if (!this.chart) {
          this.initChart();
        } else {
          const data: ChartData = this.getData();
          this.chart.options = this.options;
          this.chart.data = data;
          this.setChartYAxisMax();

          this.chart.update();
        }
      } catch (e) {
        this.toast(e);
      }
      // }
      this.loading = false;
    });
  }

  public getData(): ChartData | any {
    const values = formatMilkingHoldingData(
      this.timePeriod,
      this.day,
      this.location,
      this.modalPeriod,
      this.graphData,
      this.volumeData,
      this.selectedEntity
    );

    return {
      datasets: [
        {
          label: 'Milking',
          data: values.milkingData,
          borderColor: '#666666',
          backgroundColor: this.colours[1],
          datalabels: {
            display: false
          }
        },
        {
          label: 'Holding',
          data: values.holdingData,
          borderColor: '#666666',
          backgroundColor: this.colours[0],
          datalabels: {
            display: false
          }
        }
      ],
      labels: values.labels
    };
  }

  public setChartYAxisMax() {
    if (
      this.chart?.options?.scales?.yAxes &&
      this.chart?.options?.scales?.yAxes[0].ticks
    ) {
      //This sets the graph to display the vat capacity as the maximum when the graph is empty
      this.chart.options.scales.yAxes[0].ticks.suggestedMax = !this.noData
        ? 0
        : this.selectedEntity
        ? (this.selectedEntity.metrics as MilkMetrics).capacity
        : 5000;
    }
  }

  public toast(e: any) {
    this.$bvToast.toast(`Graph not updated - ${e}`, {
      title: 'Internal error',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
  }
}
