
import { Product } from '@/store/models/Product';
import { getDayjsFromUnix, getToday } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import MilkingTimeBar from '../graphs/MilkingTimeBar.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    MilkingTimeBar
  },
  methods: {
    getToday,
    getDayjsFromUnix
  }
})
export default class MilkingTimeBarModal extends mixins(AppName) {
  @Prop() currentProduct!: Product;
  @Prop() isNotLive!: boolean;

  private readonly placeholderTimetamp = 1664738629;

  public closeTimeGraphModal() {
    this.$root.$emit('bv::hide::modal', 'timeGraphModal', '#btnHide');
  }
}
