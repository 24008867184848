var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentEntity)?_c('div',{staticClass:"mb-3 mt-4"},[_c('div',{class:`${_vm.isFonterra ? 'pt-2' : ''} px-1 pb-2 mb-1 bg-lightGrey rounded`},[(!_vm.isFonterra)?_c('div',{staticClass:"d-flex justify-content-between"},_vm._l((_vm.thresholds),function(threshold,index){return _c('p',{key:index,staticClass:"smallText text-mediumGrey mb-0",class:_vm.textAlign(index),staticStyle:{"width":"50px"}},[_vm._v(" "+_vm._s(threshold)+" ")])}),0):_vm._e(),_c('div',[_c('BRow',{class:`${_vm.isFonterra ? 'fonterraWrapper' : ''} mx-0`},_vm._l((_vm.colours),function(colour,index){return _c('div',{key:colour,class:_vm.isFonterra ? '' : 'px-1',staticStyle:{"width":"25%"}},[_c('div',{class:[
              _vm.isFonterra ? 'fonterraTwoToneBlock' : 'twoToneBlock',
              'p-2',
              colour === 'green' ? 'first' : '',
              colour === 'red' ? 'last' : ''
            ],style:(` --colour: var(--${colour});  --darkerColour: var(--dark${_vm.toTitleCase(
                colour
              )})`),attrs:{"id":colour}},[(_vm.isCurrentScoreBlock(index))?_c('MilkScoreTooltip',{attrs:{"colour":colour,"scaleWidth":_vm.getScaleWidth(colour),"thresholdRange":_vm.thresholdRange,"isFonterra":_vm.isFonterra,"currentLevel":_vm.getCurrentLevel}}):_vm._e()],1)])}),0)],1)]),_c('div',{staticClass:"d-flex justify-content-around"},_vm._l((_vm.subTitles),function(subtitle){return _c('p',{key:subtitle,staticClass:"mb-0 smallText",staticStyle:{"width":"70px"}},[_vm._v(" "+_vm._s(subtitle.toUpperCase().replace('NO', 'NONE'))+" ")])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }