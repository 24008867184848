var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('BRow',[_c('BCol',[_c('label',{attrs:{"for":"collectionDateFrom"}},[_vm._v("From")]),_c('BFormDatepicker',{staticClass:"mb-2",attrs:{"id":"collectionDateFrom","date-format-options":{
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          weekday: 'short'
        },"state":_vm.collectionsDate.from == '' || _vm.collectionsDate.to == ''
            ? null
            : _vm.isFromBeforeTo,"max":_vm.max},model:{value:(_vm.collectionsDate.from),callback:function ($$v) {_vm.$set(_vm.collectionsDate, "from", $$v)},expression:"collectionsDate.from"}})],1),_c('BCol',[_c('label',{attrs:{"for":"collectionDateTo"}},[_vm._v("To")]),_c('BFormDatepicker',{staticClass:"mb-2",attrs:{"id":"collectionDateTo","date-format-options":{
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          weekday: 'short'
        },"state":_vm.collectionsDate.from == '' || _vm.collectionsDate.to == ''
            ? null
            : _vm.isFromBeforeTo,"max":_vm.max},model:{value:(_vm.collectionsDate.to),callback:function ($$v) {_vm.$set(_vm.collectionsDate, "to", $$v)},expression:"collectionsDate.to"}})],1)],1),(
      _vm.collectionsDate.from != '' &&
        _vm.collectionsDate.to != '' &&
        !_vm.isFromBeforeTo
    )?_c('p',{staticClass:"text-danger text-center"},[_vm._v(" From date must be before to date ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }